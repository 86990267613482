/* tslint:disable */
/* eslint-disable */
/**
 * channels-com
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface BodyUpdateCartItemCartsItemPut
 */
export interface BodyUpdateCartItemCartsItemPut {
  /**
   *
   * @type {number}
   * @memberof BodyUpdateCartItemCartsItemPut
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof BodyUpdateCartItemCartsItemPut
   */
  quantity: number;
}

export function BodyUpdateCartItemCartsItemPutFromJSON(
  json: any
): BodyUpdateCartItemCartsItemPut {
  return BodyUpdateCartItemCartsItemPutFromJSONTyped(json, false);
}

export function BodyUpdateCartItemCartsItemPutFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BodyUpdateCartItemCartsItemPut {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    quantity: json['quantity'],
  };
}

export function BodyUpdateCartItemCartsItemPutToJSON(
  value?: BodyUpdateCartItemCartsItemPut | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    quantity: value.quantity,
  };
}
