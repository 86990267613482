import { zodResolver } from '@hookform/resolvers/zod';
import { CountryList, InputWrapper, useAlerts } from '@resal-frontend/ui/index';
import { useTokenStore } from '@stores';
import { CreateUserUsersRegisterPostRequest } from '@httpclient';

import React from 'react';
import { useForm } from 'react-hook-form';
import { IntlShape, useIntl } from 'react-intl';
import { useMutation } from 'react-query';
import { usersApi } from '../../utils/api';
import { z } from 'zod';

import messages from './messages';

export interface RegisterProps {
  login: () => void;
  verify: (mobile: string) => void;
}
const schema = (formatMessage: IntlShape['formatMessage']) =>
  z.object({
    country: z.number().positive(formatMessage(messages.required)),
    mobile: z.string().nonempty(formatMessage(messages.required)),
    name: z
      .string()
      .regex(new RegExp('^[A-Za-z0-9 ]*$'), 'Only numbers and letters')
      .nonempty(formatMessage(messages.required)),
    channelName: z
      .string()
      .regex(new RegExp('^[A-Za-z0-9 ]*$'), 'Only numbers and letters')
      .nonempty(formatMessage(messages.required)),
    password: z
      .string()
      .min(8, formatMessage(messages.passwordMinChar))
      .nonempty(formatMessage(messages.required)),
    email: z
      .string()
      .email(formatMessage(messages.invalidEmail))
      .nonempty(formatMessage(messages.required)),
  });

export const Register = ({ login, verify }: RegisterProps) => {
  const { formatMessage, locale } = useIntl();
  const { setTokens, setUser } = useTokenStore();
  const { register, handleSubmit, errors, setError } = useForm({
    resolver: zodResolver(schema(formatMessage)),
  });
  const { addAlert } = useAlerts();
  const { mutate, isLoading } = useMutation(
    (args: CreateUserUsersRegisterPostRequest) =>
      usersApi.createUserUsersRegisterPost(args),
    {
      onSuccess: (data: any) => {
        login();
        addAlert({ message: data.message });
      },
      onError: async (error: any) => {
        error = await error.json();
        addAlert({ message: error.detail });
      },
    }
  );

  return (
    <>
      <h1 className="mt-8 header-text">{formatMessage(messages.register)}</h1>
      <form
        className="space-y-4 mt-3"
        onSubmit={handleSubmit((d) => {
          // Updated the phone to compine country code and the phone number
          mutate({
            userCreate: {
              channelName: d.channelName,
              name: d.name,
              email: d.email,
              password: d.password,
              phone: `${d.country}${
                d.mobile[0] === '0' ? d.mobile.slice(1) : d.mobile
              }`,
            },
            acceptLanguage: locale,
          });
        })}
      >
        <div>
          <InputWrapper
            label={formatMessage(messages.channelName)}
            name="channelName"
            error={errors?.channelName?.message}
          >
            <input
              dir="ltr"
              type="text"
              name="channelName"
              id="channelName"
              className="focus:ring-identity-primary focus:border-identity-primary block w-full sm:text-sm border-gray-300 rounded-md text-left mt-1"
              ref={register}
            />
          </InputWrapper>
        </div>
        <div>
          <InputWrapper
            label={formatMessage(messages.name)}
            name="channelName"
            error={errors?.name?.message}
          >
            <input
              dir="ltr"
              type="text"
              name="name"
              id="name"
              className="focus:ring-identity-primary focus:border-identity-primary block w-full sm:text-sm border-gray-300 rounded-md text-left mt-1"
              ref={register}
            />
          </InputWrapper>
        </div>
        <div>
          <InputWrapper
            label={formatMessage(messages.mobileNumber)}
            name="mobile"
            error={errors?.mobile?.message}
          >
            <div className="mt-1 relative rounded-md shadow-sm">
              <div className="absolute inset-y-0 left-0 flex items-center">
                <label htmlFor="country" className="sr-only">
                  Country
                </label>
                <select
                  dir="ltr"
                  id="country"
                  name="country"
                  className="focus:ring-identity-primary focus:border-identity-primary h-full py-0 pl-3 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md w-24"
                  defaultValue="966"
                  ref={register({ valueAsNumber: true })}
                >
                  <CountryList />
                </select>
              </div>
              <input
                dir="ltr"
                type="tel"
                name="mobile"
                id="mobile"
                className="focus:ring-identity-primary focus:border-identity-primary block w-full pl-24 sm:text-sm border-gray-300 rounded-md text-left"
                ref={register}
              />
            </div>
          </InputWrapper>
        </div>
        <div>
          <InputWrapper
            label={formatMessage(messages.email)}
            name="email"
            error={errors?.email?.message}
          >
            <input
              dir="ltr"
              type="email"
              name="email"
              id="email"
              className="focus:ring-identity-primary focus:border-identity-primary block w-full sm:text-sm border-gray-300 rounded-md text-left mt-1"
              ref={register}
            />
          </InputWrapper>
        </div>
        <div>
          <InputWrapper
            name="password"
            label={formatMessage(messages.password)}
            error={errors?.password?.message}
          >
            <input
              dir="ltr"
              type="password"
              name="password"
              id="password"
              autoComplete="new-password"
              className="focus:ring-identity-primary focus:border-identity-primary block w-full sm:text-sm border-gray-300 rounded-md text-left mt-1"
              ref={register}
            />
          </InputWrapper>
        </div>
        <div>
          <button
            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-identity-primary-inverted bg-identity-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-identity-primary disabled:opacity-60"
            disabled={isLoading}
          >
            {formatMessage(messages.register)}
          </button>
        </div>
        <div>
          {formatMessage(messages.haveAcct)}
          <button
            type="button"
            className="text-identity-primary mis-1"
            onClick={login}
          >
            {formatMessage(messages.login)}
          </button>
        </div>
      </form>
    </>
  );
};
