import { Transition } from '@headlessui/react';
import React, { useRef, useState } from 'react';
import { useClickAway } from 'react-use';

interface CountryItem {
  id: number;
  title: string;
  flag: string;
}
export interface CountryPickerProps {
  currentCountry?: string;
  items: CountryItem[];
  onChange: (countryId: number) => void;
}

export function CountryPicker({
  currentCountry,
  items,
  onChange,
}: CountryPickerProps) {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);
  useClickAway(ref, (evt) => {
    if (
      !isOpen ||
      (evt.target instanceof Element &&
        evt.target.id === 'country-options-menu')
    ) {
      return;
    }
    setIsOpen(false);
  });
  return (
    <div className="relative inline-block text-left">
      <div className="h-full flex">
        <button
          type="button"
          className="inline-flex items-center justify-center w-full rounded-md shadow-sm px-4 py-2 bg-identity-tertiary text-sm font-medium text-identity-tertiary-inverted focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-identity-primary"
          id="country-options-menu"
          aria-expanded="true"
          aria-haspopup="true"
          onClick={() => {
            setIsOpen((open) => !open);
          }}
        >
          <svg
            aria-hidden
            className="-mis-1 mie-2 h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
            />
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
            />
          </svg>
          {currentCountry ?? items?.[0]?.title}
        </button>
      </div>
      <div ref={ref}>
        <Transition
          className="origin-top-right z-10 absolute inline-start-0 mt-2 w-40 rounded shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="country-options-menu"
          show={isOpen}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          {items.map((item) => (
            <div className="py-1" role="none" key={item.id}>
              <button
                onClick={() => {
                  onChange(item.id);
                  setIsOpen(false);
                }}
                className="group flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full"
                role="menuitem"
              >
                <img
                  className="mie-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                  src={item.flag}
                  aria-hidden="true"
                  alt=""
                />
                {item.title}
              </button>
            </div>
          ))}
        </Transition>
      </div>
    </div>
  );
}

export default CountryPicker;
