import { defineMessages } from 'react-intl';

export default defineMessages({
  shopOnApps: {
    id: 'footer.shopapps',
    defaultMessage: 'Shop on app',
  },
  footerResalSection: {
    id: 'footer.items.resal',
    defaultMessage: 'Resal',
  },
  footerResalStory: {
    id: 'footer.items.resal.story',
    defaultMessage: 'Resal Story',
  },
  footerResalBlog: {
    id: 'footer.items.resal.blog',
    defaultMessage: 'Resal Blog',
  },
  footerResalJoin: {
    id: 'footer.items.resal.join',
    defaultMessage: 'Join Resal',
  },
  footerServicesSection: {
    id: 'footer.items.services',
    defaultMessage: 'Our Servies',
  },
  footerServicesEGifts: {
    id: 'footer.items.services.egifts',
    defaultMessage: 'Gift Cards',
  },
  footerServicesEGiftChannels: {
    id: 'footer.items.services.egiftChannels',
    defaultMessage: 'Resal Channels',
  },
  footerServicesGlee: {
    id: 'footer.items.services.glee',
    defaultMessage: 'Resal Glee',
  },
  footerServicesPartner: {
    id: 'footer.items.services.partner',
    defaultMessage: 'Gift cards Provider Partnerships',
  },
  footerHelpSection: {
    id: 'footer.items.help',
    defaultMessage: 'Help',
  },
  footerHelpContact: {
    id: 'footer.items.help.contact',
    defaultMessage: 'Contact us',
  },
  footerHelpFAQ: {
    id: 'footer.items.help.faq',
    defaultMessage: 'Frequent Questions',
  },
  footerHelpTOS: {
    id: 'footer.items.help.tos',
    defaultMessage: 'Terms of Service',
  },
  footerCopyright: {
    id: 'footer.copyright',
    defaultMessage: 'All rights reserver {cpy} Powered by <a>Resal</a>',
  },
  balance: {
    id: 'header.balance',
    defaultMessage: 'You have {balance}',
  },
  privacy: {
    id: 'footer.privacy',
    defaultMessage: 'Privacy Policy',
  },
  goToCart: {
    id: 'header.goToCart',
    defaultMessage: 'Go to Cart',
  },
  logout: {
    id: 'header.logout',
    defaultMessage: 'Logout',
  },
  login: {
    id: 'header.login',
    defaultMessage: 'Login',
  },
  emptyCart: {
    id: 'header.emptyCart',
    defaultMessage: 'Your cart is empty',
  },
  title: {
    id: 'seo.title',
    defaultMessage: 'Resal Channels for Gift Cards',
  },
  description: {
    id: 'seo.description',
    defaultMessage:
      "Now you can access our huge digital cards  selection and display it on your website easily. Sell digital cards from Resal's + 300 partners and increase your revenue.",
  },
  titleTemplate: {
    id: 'seo.titleTemplate',
    defaultMessage: '%s',
  },
  categories: {
    id: 'header.categories',
    defaultMessage: 'Gift cards',
  },
  offers: {
    id: 'header.offers',
    defaultMessage: 'Offers',
  },
});
