import { defineMessages } from 'react-intl';

export default defineMessages({
  login: {
    id: 'login.header',
    defaultMessage: 'Login',
  },
  mobile: {
    id: 'login.mobile',
    defaultMessage: 'Mobile',
  },
  email: {
    id: 'login.email',
    defaultMessage: 'Email',
  },
  password: {
    id: 'login.password',
    defaultMessage: 'Password',
  },
  register: {
    id: 'register.header',
    defaultMessage: 'Register',
  },
  channelName: {
    id: 'register.channelName',
    defaultMessage: 'Channel Name',
  },
  name: {
    id: 'register.name',
    defaultMessage: 'Name',
  },
  required: {
    id: 'required',
    defaultMessage: 'Required',
  },
  invalidEmail: {
    id: 'invalidEmail',
    defaultMessage: 'Invalid email',
  },
  passwordMinChar: {
    id: 'passwordMinChar',
    defaultMessage: '8 Characters min',
  },
  mobileNumber: {
    id: 'mobileNumber',
    defaultMessage: 'Mobile number',
  },
  verify: {
    id: 'verify',
    defaultMessage: 'Enter the activation code',
  },
  verifySent: {
    id: 'verifySent',
    defaultMessage: 'We sent a one time password to the number',
  },
  verifyBtn: {
    id: 'verifyBtn',
    defaultMessage: 'Verify',
  },
  smsTimeRemaining: {
    id: 'smsTimeRemaining',
    defaultMessage: 'Resend Message after',
  },
  resendBtn: {
    id: 'resendBtn',
    defaultMessage: 'Resend',
  },
  forgotPassword: {
    id: 'landing.forgotPassword',
    defaultMessage: 'Forgot your password?',
  },
  forgotPasswordMessage: {
    id: 'landing.forgotPasswordMessage',
    defaultMessage:
      'Add your email address and we will send you a link to reset your password',
  },
  resetPassword: {
    id: 'landing.resetPassword',
    defaultMessage: 'Reset Password',
  },
  backToLogin: {
    id: 'landing.backToLogin',
    defaultMessage: 'Back to Login',
  },
  passwordMismatch: {
    id: 'landing.passwordMismatch',
    defaultMessage: "Passwords don't match",
  },
  confirmPassword: {
    id: 'landing.confirmPassword',
    defaultMessage: 'Confirm Password',
  },
  dontHaveAcct: {
    id: 'landing.dontHaveAcct',
    defaultMessage: "Don't have an account?",
  },
  orContinueWith: {
    id: 'landing.orContinueWith',
    defaultMessage: 'Or continue with',
  },
  facebook: {
    id: 'landing.facebook',
    defaultMessage: 'Facebook',
  },
  google: {
    id: 'landing.google',
    defaultMessage: 'Google',
  },
  haveAcct: {
    id: 'landing.haveAcct',
    defaultMessage: 'Already have an account?',
  },
  resetSent: {
    id: 'landing.resetSent',
    defaultMessage: 'We sent you an email to reset your password',
  },
});
