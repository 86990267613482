import {
  Footer,
  FooterProps,
  Header,
  HeaderProps,
} from '@resal-frontend/ui/index';
import { useTokenStore } from '@stores';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import pmessages from '../../components/profile/messages';
import { useLogout } from '../../utils/logout';
import { navItems } from '../../utils/profile-navitems';
import { useSyncCart } from '../../utils/sync-cart';
import { UIStateStore } from '@stores';
import { LoginRegister } from '../login';
import messages from './messages';
import { useCurrency } from '../../utils/currency-hook';
import { DefaultSeo } from 'next-seo';
import { useChannel } from '../../utils/channel-context';
import { useQuery } from 'react-query';
import { usersApi, categoriesApi } from '../../utils/api';
import { CartItem } from '@httpclient';

export interface LayoutProps {
  children: ReactNode;
  protectedPage?: boolean;
}

export function Layout({ children, protectedPage = true }: LayoutProps) {
  const { setUIMode } = UIStateStore();
  const loggedIn = useTokenStore((store) => store.loggedIn());
  const { user, setUser } = useTokenStore();
  const { formatMessage, locale } = useIntl();
  const { push } = useRouter();

  useEffect(() => {
    if (!loggedIn && protectedPage) setUIMode('login');
  }, []);
  useQuery(
    'Me',
    async () =>
      await usersApi.getUserDataUsersMeGet({ acceptLanguage: locale }),
    {
      staleTime: Infinity,
      enabled: loggedIn,
      onSuccess(data) {
        if (data.user == null || user == null) return;
        const updatedUser = data.user;
        setUser({
          ...user,
          ...updatedUser,
        });
      },
      notifyOnChangeProps: ['data'],
    }
  );
  const [mergedCartItems, setMergedCartItems] = useState<
    CartItem[] | undefined
  >();
  useSyncCart(setMergedCartItems);
  const [items] = useState<HeaderProps['items']>(() => {
    return [
      process.env.NEXT_PUBLIC_FEAT_OFFERS === '1'
        ? {
            href: '/offers',
            label: formatMessage(messages.offers),
          }
        : null,
    ].filter(Boolean) as Array<{ href: string; label: string }>;
  });
  const { data: categoriesRes } = useQuery(
    'getCategories',
    async () =>
      await categoriesApi.listCategoriesCategoriesGet({
        acceptLanguage: locale,
      }),
    {
      enabled: loggedIn,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );
  const [catItems] = useState<HeaderProps['categoryNavItems']>([]);
  const footerItems = useMemo<FooterProps['links']>(() => {
    return [
      {
        title: formatMessage(messages.footerHelpSection),
        items: [
          {
            label: formatMessage(messages.footerHelpTOS),
            path: '/service-agreement',
          },
          {
            label: formatMessage(messages.privacy),
            path: '/privacy-policy',
          },
        ],
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);
  const logout = useLogout();
  const channel = useChannel();
  const profileItems = useMemo(() => {
    return navItems.map((item) => ({
      ...item,
      title: formatMessage(pmessages[item.id]),
      path: `/profile/${item.path}`,
    }));
  }, [locale]);
  const formatCurrency = useCurrency();
  const seoConfig = useMemo(
    () => ({
      openGraph: {
        title: formatMessage(messages.title),
        description: formatMessage(messages.description),
        type: 'website',
        locale: locale,
        url: 'https://channels.resal.me',
        images: [
          {
            url: 'https://channels-s3-dev.s3.us-east-1.amazonaws.com/Unknown.png',
            width: 499,
            height: 333,
            alt: formatMessage(messages.title),
          },
        ],
      },
      twitter: {
        handle: '@Resal_Me',
        site: '@Resal_Me',
        cardType: 'summary_large_image',
      },
    }),
    [locale]
  );

  return (
    <div className="min-h-screen flex flex-col">
      <DefaultSeo
        title={formatMessage(messages.title)}
        titleTemplate={formatMessage(messages.titleTemplate)}
        description={formatMessage(messages.description)}
        openGraph={seoConfig.openGraph}
        twitter={seoConfig.twitter}
      />
      <Head>
        <link rel="shortcut icon" href="/favicon.ico" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/fav/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/fav/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/fav/favicon-16x16.png"
        />
        <link rel="manifest" href="/fav/site.webmanifest" />
        <link
          rel="mask-icon"
          href="/fav/safari-pinned-tab.svg"
          color="#5bbad5"
        />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
      </Head>
      <Header
        items={items}
        categoryNavItems={categoriesRes?.categories ?? catItems}
        loggedIn={loggedIn}
        onLoginClick={() => {
          setUIMode('login');
        }}
        cartItems={mergedCartItems}
        currency={locale === 'en' ? 'S.R' : 'ر.س'}
        balance={formatMessage(messages.balance, {
          balance: formatCurrency(user?.channel?.balance ?? 0),
        })}
        onLogout={logout}
        profileNavItems={profileItems}
        onBalanceClick={() => {
          push('/profile/balance');
        }}
        loginMessage={formatMessage(messages.login)}
        logoutMessage={formatMessage(messages.logout)}
        emptyMessage={formatMessage(messages.emptyCart)}
        gotoMessage={formatMessage(messages.goToCart)}
        categoryMessage={formatMessage(messages.categories)}
        logo={{
          src: channel.logo.url,
          alt: channel.name,
          width: (channel.logo.width * 48) / channel.logo.height,
          height: 48,
          layout: 'intrinsic',
          priority: true,
        }}
        locale={locale}
      />
      <LoginRegister />
      <div className="max-w-screen-xl mx-auto py-4 px-4 sm:px-6 lg:px-8 w-full flex-1 layout-bg">
        {children}
      </div>
      <Footer
        shopMessage={formatMessage(messages.shopOnApps)}
        links={footerItems}
        copyrightMessage={formatMessage(messages.footerCopyright, {
          cpy: '©',
          a: (chunks) => (
            <a target="_blank" rel="noopener noreferer" href="https://resal.me">
              {chunks}
            </a>
          ),
        })}
        logo={{
          src: channel.logo.url,
          alt: channel.name,
          width: (channel.logo.width * 43) / channel.logo.height,
          height: 43,
          layout: 'intrinsic',
          priority: true,
        }}
      />
    </div>
  );
}

export default Layout;
