/* tslint:disable */
/* eslint-disable */
/**
 * channels-com
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  BodyVerifyWihTokenUsersActivatePost,
  BodyVerifyWihTokenUsersActivatePostFromJSON,
  BodyVerifyWihTokenUsersActivatePostToJSON,
  HTTPValidationError,
  HTTPValidationErrorFromJSON,
  HTTPValidationErrorToJSON,
  LoginResponse,
  LoginResponseFromJSON,
  LoginResponseToJSON,
  MinBalance,
  MinBalanceFromJSON,
  MinBalanceToJSON,
  MinBalanceResponse,
  MinBalanceResponseFromJSON,
  MinBalanceResponseToJSON,
  RecoverEmail,
  RecoverEmailFromJSON,
  RecoverEmailToJSON,
  RegisterResponse,
  RegisterResponseFromJSON,
  RegisterResponseToJSON,
  ResetPasswordResponse,
  ResetPasswordResponseFromJSON,
  ResetPasswordResponseToJSON,
  ResetPasswordWithToken,
  ResetPasswordWithTokenFromJSON,
  ResetPasswordWithTokenToJSON,
  UserCreate,
  UserCreateFromJSON,
  UserCreateToJSON,
  UserLogin,
  UserLoginFromJSON,
  UserLoginToJSON,
  UserUpdatePassword,
  UserUpdatePasswordFromJSON,
  UserUpdatePasswordToJSON,
} from '../models';

export interface ChangePasswordUsersPasswordPostRequest {
  userUpdatePassword: UserUpdatePassword;
  acceptLanguage?: string;
}

export interface CreateUserUsersRegisterPostRequest {
  userCreate: UserCreate;
  acceptLanguage?: string;
}

export interface GetUserDataUsersMeGetRequest {
  acceptLanguage?: string;
}

export interface LoginAccessTokenUsersLoginPostRequest {
  userLogin: UserLogin;
  acceptLanguage?: string;
}

export interface RecoverPasswordUsersPasswordRecoveryPostRequest {
  recoverEmail: RecoverEmail;
  acceptLanguage?: string;
}

export interface ResetPasswordUsersResetPasswordPostRequest {
  resetPasswordWithToken: ResetPasswordWithToken;
  acceptLanguage?: string;
}

export interface UpdateLowBalanceUsersMinBalancePostRequest {
  minBalance: MinBalance;
  acceptLanguage?: string;
}

export interface VerifyWihTokenUsersActivatePostRequest {
  bodyVerifyWihTokenUsersActivatePost: BodyVerifyWihTokenUsersActivatePost;
  acceptLanguage?: string;
}

/**
 *
 */
export class UsersApi extends runtime.BaseAPI {
  /**
   * Change own user password.
   * Change Password
   */
  async changePasswordUsersPasswordPostRaw(
    requestParameters: ChangePasswordUsersPasswordPostRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<any>> {
    if (
      requestParameters.userUpdatePassword === null ||
      requestParameters.userUpdatePassword === undefined
    ) {
      throw new runtime.RequiredError(
        'userUpdatePassword',
        'Required parameter requestParameters.userUpdatePassword was null or undefined when calling changePasswordUsersPasswordPost.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      requestParameters.acceptLanguage !== undefined &&
      requestParameters.acceptLanguage !== null
    ) {
      headerParameters['accept-language'] = String(
        requestParameters.acceptLanguage
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('HTTPBearer', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/users/password`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: UserUpdatePasswordToJSON(requestParameters.userUpdatePassword),
      },
      initOverrides
    );

    return new runtime.TextApiResponse(response) as any;
  }

  /**
   * Change own user password.
   * Change Password
   */
  async changePasswordUsersPasswordPost(
    requestParameters: ChangePasswordUsersPasswordPostRequest,
    initOverrides?: RequestInit
  ): Promise<any> {
    const response = await this.changePasswordUsersPasswordPostRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Register new user.
   * Create User
   */
  async createUserUsersRegisterPostRaw(
    requestParameters: CreateUserUsersRegisterPostRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<RegisterResponse>> {
    if (
      requestParameters.userCreate === null ||
      requestParameters.userCreate === undefined
    ) {
      throw new runtime.RequiredError(
        'userCreate',
        'Required parameter requestParameters.userCreate was null or undefined when calling createUserUsersRegisterPost.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      requestParameters.acceptLanguage !== undefined &&
      requestParameters.acceptLanguage !== null
    ) {
      headerParameters['accept-language'] = String(
        requestParameters.acceptLanguage
      );
    }

    const response = await this.request(
      {
        path: `/users/register`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: UserCreateToJSON(requestParameters.userCreate),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      RegisterResponseFromJSON(jsonValue)
    );
  }

  /**
   * Register new user.
   * Create User
   */
  async createUserUsersRegisterPost(
    requestParameters: CreateUserUsersRegisterPostRequest,
    initOverrides?: RequestInit
  ): Promise<RegisterResponse> {
    const response = await this.createUserUsersRegisterPostRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Get User Data
   */
  async getUserDataUsersMeGetRaw(
    requestParameters: GetUserDataUsersMeGetRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<RegisterResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      requestParameters.acceptLanguage !== undefined &&
      requestParameters.acceptLanguage !== null
    ) {
      headerParameters['accept-language'] = String(
        requestParameters.acceptLanguage
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('HTTPBearer', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/users/me`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      RegisterResponseFromJSON(jsonValue)
    );
  }

  /**
   * Get User Data
   */
  async getUserDataUsersMeGet(
    requestParameters: GetUserDataUsersMeGetRequest,
    initOverrides?: RequestInit
  ): Promise<RegisterResponse> {
    const response = await this.getUserDataUsersMeGetRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Login Access Token
   */
  async loginAccessTokenUsersLoginPostRaw(
    requestParameters: LoginAccessTokenUsersLoginPostRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<LoginResponse>> {
    if (
      requestParameters.userLogin === null ||
      requestParameters.userLogin === undefined
    ) {
      throw new runtime.RequiredError(
        'userLogin',
        'Required parameter requestParameters.userLogin was null or undefined when calling loginAccessTokenUsersLoginPost.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      requestParameters.acceptLanguage !== undefined &&
      requestParameters.acceptLanguage !== null
    ) {
      headerParameters['accept-language'] = String(
        requestParameters.acceptLanguage
      );
    }

    const response = await this.request(
      {
        path: `/users/login`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: UserLoginToJSON(requestParameters.userLogin),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      LoginResponseFromJSON(jsonValue)
    );
  }

  /**
   * Login Access Token
   */
  async loginAccessTokenUsersLoginPost(
    requestParameters: LoginAccessTokenUsersLoginPostRequest,
    initOverrides?: RequestInit
  ): Promise<LoginResponse> {
    const response = await this.loginAccessTokenUsersLoginPostRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Password Recovery Email
   * Recover Password
   */
  async recoverPasswordUsersPasswordRecoveryPostRaw(
    requestParameters: RecoverPasswordUsersPasswordRecoveryPostRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<ResetPasswordResponse>> {
    if (
      requestParameters.recoverEmail === null ||
      requestParameters.recoverEmail === undefined
    ) {
      throw new runtime.RequiredError(
        'recoverEmail',
        'Required parameter requestParameters.recoverEmail was null or undefined when calling recoverPasswordUsersPasswordRecoveryPost.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      requestParameters.acceptLanguage !== undefined &&
      requestParameters.acceptLanguage !== null
    ) {
      headerParameters['accept-language'] = String(
        requestParameters.acceptLanguage
      );
    }

    const response = await this.request(
      {
        path: `/users/password-recovery`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: RecoverEmailToJSON(requestParameters.recoverEmail),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ResetPasswordResponseFromJSON(jsonValue)
    );
  }

  /**
   * Password Recovery Email
   * Recover Password
   */
  async recoverPasswordUsersPasswordRecoveryPost(
    requestParameters: RecoverPasswordUsersPasswordRecoveryPostRequest,
    initOverrides?: RequestInit
  ): Promise<ResetPasswordResponse> {
    const response = await this.recoverPasswordUsersPasswordRecoveryPostRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Reset password with token
   * Reset Password
   */
  async resetPasswordUsersResetPasswordPostRaw(
    requestParameters: ResetPasswordUsersResetPasswordPostRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<ResetPasswordResponse>> {
    if (
      requestParameters.resetPasswordWithToken === null ||
      requestParameters.resetPasswordWithToken === undefined
    ) {
      throw new runtime.RequiredError(
        'resetPasswordWithToken',
        'Required parameter requestParameters.resetPasswordWithToken was null or undefined when calling resetPasswordUsersResetPasswordPost.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      requestParameters.acceptLanguage !== undefined &&
      requestParameters.acceptLanguage !== null
    ) {
      headerParameters['accept-language'] = String(
        requestParameters.acceptLanguage
      );
    }

    const response = await this.request(
      {
        path: `/users/reset-password`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ResetPasswordWithTokenToJSON(
          requestParameters.resetPasswordWithToken
        ),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ResetPasswordResponseFromJSON(jsonValue)
    );
  }

  /**
   * Reset password with token
   * Reset Password
   */
  async resetPasswordUsersResetPasswordPost(
    requestParameters: ResetPasswordUsersResetPasswordPostRequest,
    initOverrides?: RequestInit
  ): Promise<ResetPasswordResponse> {
    const response = await this.resetPasswordUsersResetPasswordPostRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Update channel min balance that will trigger an email to notify the selected emails in another endpoint
   * Update Low Balance
   */
  async updateLowBalanceUsersMinBalancePostRaw(
    requestParameters: UpdateLowBalanceUsersMinBalancePostRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<MinBalanceResponse>> {
    if (
      requestParameters.minBalance === null ||
      requestParameters.minBalance === undefined
    ) {
      throw new runtime.RequiredError(
        'minBalance',
        'Required parameter requestParameters.minBalance was null or undefined when calling updateLowBalanceUsersMinBalancePost.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      requestParameters.acceptLanguage !== undefined &&
      requestParameters.acceptLanguage !== null
    ) {
      headerParameters['accept-language'] = String(
        requestParameters.acceptLanguage
      );
    }

    const response = await this.request(
      {
        path: `/users/min-balance`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: MinBalanceToJSON(requestParameters.minBalance),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      MinBalanceResponseFromJSON(jsonValue)
    );
  }

  /**
   * Update channel min balance that will trigger an email to notify the selected emails in another endpoint
   * Update Low Balance
   */
  async updateLowBalanceUsersMinBalancePost(
    requestParameters: UpdateLowBalanceUsersMinBalancePostRequest,
    initOverrides?: RequestInit
  ): Promise<MinBalanceResponse> {
    const response = await this.updateLowBalanceUsersMinBalancePostRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * used after new account email is sent to activate account
   * Verify Wih Token
   */
  async verifyWihTokenUsersActivatePostRaw(
    requestParameters: VerifyWihTokenUsersActivatePostRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<LoginResponse>> {
    if (
      requestParameters.bodyVerifyWihTokenUsersActivatePost === null ||
      requestParameters.bodyVerifyWihTokenUsersActivatePost === undefined
    ) {
      throw new runtime.RequiredError(
        'bodyVerifyWihTokenUsersActivatePost',
        'Required parameter requestParameters.bodyVerifyWihTokenUsersActivatePost was null or undefined when calling verifyWihTokenUsersActivatePost.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      requestParameters.acceptLanguage !== undefined &&
      requestParameters.acceptLanguage !== null
    ) {
      headerParameters['accept-language'] = String(
        requestParameters.acceptLanguage
      );
    }

    const response = await this.request(
      {
        path: `/users/activate`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: BodyVerifyWihTokenUsersActivatePostToJSON(
          requestParameters.bodyVerifyWihTokenUsersActivatePost
        ),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      LoginResponseFromJSON(jsonValue)
    );
  }

  /**
   * used after new account email is sent to activate account
   * Verify Wih Token
   */
  async verifyWihTokenUsersActivatePost(
    requestParameters: VerifyWihTokenUsersActivatePostRequest,
    initOverrides?: RequestInit
  ): Promise<LoginResponse> {
    const response = await this.verifyWihTokenUsersActivatePostRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }
}
