/* tslint:disable */
/* eslint-disable */
/**
 * channels-com
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ApiKey
 */
export interface ApiKey {
  /**
   *
   * @type {string}
   * @memberof ApiKey
   */
  secret: string;
  /**
   *
   * @type {string}
   * @memberof ApiKey
   */
  token?: string;
  /**
   *
   * @type {boolean}
   * @memberof ApiKey
   */
  isRevoked: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ApiKey
   */
  isLegacy: boolean;
  /**
   *
   * @type {Date}
   * @memberof ApiKey
   */
  createdAt: Date;
  /**
   *
   * @type {Date}
   * @memberof ApiKey
   */
  revokedAt?: Date;
  /**
   *
   * @type {number}
   * @memberof ApiKey
   */
  channelId: number;
}

export function ApiKeyFromJSON(json: any): ApiKey {
  return ApiKeyFromJSONTyped(json, false);
}

export function ApiKeyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ApiKey {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    secret: json['secret'],
    token: !exists(json, 'token') ? undefined : json['token'],
    isRevoked: json['is_revoked'],
    isLegacy: json['is_legacy'],
    createdAt: new Date(json['created_at']),
    revokedAt: !exists(json, 'revoked_at')
      ? undefined
      : new Date(json['revoked_at']),
    channelId: json['channel_id'],
  };
}

export function ApiKeyToJSON(value?: ApiKey | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    secret: value.secret,
    token: value.token,
    is_revoked: value.isRevoked,
    is_legacy: value.isLegacy,
    created_at: value.createdAt.toISOString(),
    revoked_at:
      value.revokedAt === undefined ? undefined : value.revokedAt.toISOString(),
    channel_id: value.channelId,
  };
}
