/* tslint:disable */
/* eslint-disable */
/**
 * channels-com
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface BodyAddCartItemCartsItemPost
 */
export interface BodyAddCartItemCartsItemPost {
  /**
   *
   * @type {number}
   * @memberof BodyAddCartItemCartsItemPost
   */
  quantity: number;
  /**
   *
   * @type {number}
   * @memberof BodyAddCartItemCartsItemPost
   */
  denominationId: number;
}

export function BodyAddCartItemCartsItemPostFromJSON(
  json: any
): BodyAddCartItemCartsItemPost {
  return BodyAddCartItemCartsItemPostFromJSONTyped(json, false);
}

export function BodyAddCartItemCartsItemPostFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BodyAddCartItemCartsItemPost {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    quantity: json['quantity'],
    denominationId: json['denomination_id'],
  };
}

export function BodyAddCartItemCartsItemPostToJSON(
  value?: BodyAddCartItemCartsItemPost | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    quantity: value.quantity,
    denomination_id: value.denominationId,
  };
}
