import { useCallback } from 'react';
import { useTokenStore, UIStateStore } from '@stores';
import { useRouter } from 'next/router';
import { CartStore } from './cart-store';
import { setSiftUser } from '../utils/sift';
import { useQueryClient } from 'react-query';

export const useLogout = () => {
  const { accessToken, clearTokens } = useTokenStore();
  const { setUIMode } = UIStateStore();
  const queryClient = useQueryClient();

  const { clear } = CartStore();
  const { push } = useRouter();

  const cb = useCallback(() => {
    if (accessToken === '') return;
    push('/');
    clearTokens();
    clear();
    queryClient.removeQueries('getOrders', { exact: true });
    setSiftUser('');
    setUIMode('login');
  }, [accessToken]);
  return cb;
};
