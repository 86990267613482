import messages from '../components/profile/messages';

export const navItems: Array<{
  id: keyof typeof messages;
  icon: (active?: boolean) => JSX.Element;
  path: string;
}> = [
  {
    id: 'personalInfo',
    path: 'info',
    icon: (active?: boolean) => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        stroke={active ? '#FD9A28' : 'currentColor'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="h-6 w-6"
      >
        <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2" />
        <circle cx="12" cy="7" r="4" />
      </svg>
    ),
  },
  {
    id: 'orders',
    path: 'orders',
    icon: (active?: boolean) => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke={active ? '#FD9A28' : 'currentColor'}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
        />
      </svg>
    ),
  },
  {
    id: 'balance',
    path: 'balance',
    icon: (active?: boolean) => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke={active ? '#FD9A28' : 'currentColor'}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z"
        />
      </svg>
    ),
  },
  // {
  //   id: 'calendar',
  //   path: 'calendar',
  //   icon: (active: boolean) =>(
  //     <svg
  //       xmlns="http://www.w3.org/2000/svg"
  //       className="h-6 w-6"
  //       fill="none"
  //       viewBox="0 0 24 24"
  //       stroke = { active? "#FD9A28": "currentColor" }
  //     >
  //       <path
  //         strokeLinecap="round"
  //         strokeLinejoin="round"
  //         strokeWidth={2}
  //         d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
  //       />
  //     </svg>
  //   ),
  // },
  {
    id: 'changePassword',
    path: 'change-password',
    icon: (active?: boolean) => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke={active ? '#FD9A28' : 'currentColor'}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"
        />
      </svg>
    ),
  },
  {
    id: 'apiKey',
    path: 'api-key',
    icon: (active?: boolean) => (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke={active ? '#FD9A28' : 'currentColor'}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d=" M6.86585658,10.1480963 C6.37675698,10.2925707 5.85786491,10.3702536 5.32035582,10.3702536 C2.38200444,10.3702536 0,8.04879327 0,5.18512681 C0,4.38463564 0.186127603,3.62651194 0.518505891,2.9496191 L4.38906963,6.7218036 L6.89710941,4.27751138 L3.02654568,0.505326877 C3.72109201,0.181396744 4.4989877,0 5.32035582,0 C8.25870721,0 10.6407116,2.32146034 10.6407116,5.18512681 C10.6407116,5.94596442 10.4725676,6.66852805 10.1705189,7.31944849 L16.4805679,13.4691132 C17.173144,14.1440859 17.173144,15.2384328 16.4805679,15.9134054 L15.8850668,16.4937705 C15.1924908,17.1687432 14.0696031,17.1687432 13.377027,16.4937705 L6.86585658,10.1480963 Z"
          id="Path"
        />
      </svg>
    ),
  },
];
