/* tslint:disable */
/* eslint-disable */
/**
 * channels-com
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  Meta,
  MetaFromJSON,
  MetaFromJSONTyped,
  MetaToJSON,
  Order,
  OrderFromJSON,
  OrderFromJSONTyped,
  OrderToJSON,
} from './';

/**
 *
 * @export
 * @interface ListOrdersResponse
 */
export interface ListOrdersResponse {
  /**
   *
   * @type {Array<Order>}
   * @memberof ListOrdersResponse
   */
  data: Array<Order>;
  /**
   *
   * @type {Meta}
   * @memberof ListOrdersResponse
   */
  meta: Meta;
}

export function ListOrdersResponseFromJSON(json: any): ListOrdersResponse {
  return ListOrdersResponseFromJSONTyped(json, false);
}

export function ListOrdersResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ListOrdersResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: (json['data'] as Array<any>).map(OrderFromJSON),
    meta: MetaFromJSON(json['meta']),
  };
}

export function ListOrdersResponseToJSON(
  value?: ListOrdersResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: (value.data as Array<any>).map(OrderToJSON),
    meta: MetaToJSON(value.meta),
  };
}
