/* tslint:disable */
/* eslint-disable */
/**
 * channels-com
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface Items
 */
export interface Items {
  /**
   *
   * @type {string}
   * @memberof Items
   */
  cardName: string;
  /**
   *
   * @type {string}
   * @memberof Items
   */
  variantName: string;
  /**
   *
   * @type {number}
   * @memberof Items
   */
  quantity: number;
  /**
   *
   * @type {string}
   * @memberof Items
   */
  userEmail: string;
}

export function ItemsFromJSON(json: any): Items {
  return ItemsFromJSONTyped(json, false);
}

export function ItemsFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Items {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    cardName: json['card_name'],
    variantName: json['variant_name'],
    quantity: json['quantity'],
    userEmail: json['user_email'],
  };
}

export function ItemsToJSON(value?: Items | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    card_name: value.cardName,
    variant_name: value.variantName,
    quantity: value.quantity,
    user_email: value.userEmail,
  };
}
