import Image from 'next/image';
import Link from 'next/link';
import React, { useEffect } from 'react';
import { useKeenSlider } from 'keen-slider/react';

export type CardProps =
  | {
      img: string[] | string;
      title: string;
      priceRange: string;
      link: string;
    }
  | {
      placeholderMode: true;
    };

export function Card(props: CardProps) {
  const [pause, setPause] = React.useState(true);
  const [sliderRef, slider] = useKeenSlider<HTMLDivElement>({
    duration: 800,
    rtl:
      typeof document !== 'undefined' && document.documentElement.dir === 'rtl',
    dragStart: () => {
      setPause(true);
    },
    dragEnd: () => {
      setPause(false);
    },
  });

  useEffect(() => {
    const interval = setInterval(() => {
      if (slider == null) return;
      if (!pause) {
        slider.next();
      } else if (pause) {
        slider.moveToSlide(0);
      }
    }, 200);
    return () => {
      clearInterval(interval);
    };
  }, [slider, pause]);
  if ('placeholderMode' in props) {
    return (
      <div className="w-full flex flex-col flex-shrink-0">
        <div className="aspect-w-3 aspect-h-2 rounded-md overflow-hidden shadow animate-pulse bg-gray-300" />
        <h3 className="leading-8 font-medium text-xs">
          <span className="h-4 my-2 block w-1/2 animate-pulse bg-gray-300" />
        </h3>
        <span className="h-4 block w-3/4 animate-pulse bg-gray-300" />
      </div>
    );
  }
  const imgs = Array.isArray(props.img) ? props.img : [props.img];
  return (
    <Link href={props.link}>
      <a className="w-full flex flex-col flex-shrink-0 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-identity-primary rounded-md">
        <div
          className="keen-slider"
          dir="ltr"
          ref={imgs.length > 1 ? sliderRef : undefined}
          onMouseOver={() => {
            setPause(false);
          }}
          onMouseOut={() => {
            setPause(true);
          }}
        >
          {imgs.length > 0 ? (
            imgs.map((img, idx) => (
              <div
                className="p-2 keen-slider__slide min-w-full"
                key={img + idx}
              >
                <div className="rounded-md shadow overflow-hidden">
                  <div className="aspect-w-3 aspect-h-2 relative block">
                    <Image
                      src={img}
                      layout="fill"
                      unoptimized={!img.includes('s3.amazonaws.com')}
                      objectFit="fill"
                      loading={idx > 0 ? 'lazy' : undefined}
                      sizes="calc(50vw - 3.125rem), (min-width:768px) calc(calc(50vw - 6.5rem) / 2)"
                    />
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="p-2  keen-slider__slide">
              <div className="rounded-md shadow overflow-hidden bg-gray-200">
                <div className="aspect-w-3 aspect-h-2 relative block"></div>
              </div>
            </div>
          )}
        </div>
        <h3 className="leading-8 font-medium text-xs mx-2">{props.title}</h3>
        <span className="text-xs text-rgray mx-2">{props.priceRange}</span>
      </a>
    </Link>
  );
}

export default Card;
