/* tslint:disable */
/* eslint-disable */
/**
 * channels-com
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface Category
 */
export interface Category {
  /**
   *
   * @type {number}
   * @memberof Category
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof Category
   */
  nameAr: string;
  /**
   *
   * @type {string}
   * @memberof Category
   */
  nameEn: string;
  /**
   *
   * @type {string}
   * @memberof Category
   */
  descriptionAr: string;
  /**
   *
   * @type {string}
   * @memberof Category
   */
  descriptionEn: string;
}

export function CategoryFromJSON(json: any): Category {
  return CategoryFromJSONTyped(json, false);
}

export function CategoryFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Category {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    nameAr: json['name_ar'],
    nameEn: json['name_en'],
    descriptionAr: json['description_ar'],
    descriptionEn: json['description_en'],
  };
}

export function CategoryToJSON(value?: Category | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name_ar: value.nameAr,
    name_en: value.nameEn,
    description_ar: value.descriptionAr,
    description_en: value.descriptionEn,
  };
}
