/* tslint:disable */
/* eslint-disable */
/**
 * channels-com
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  HTTPValidationError,
  HTTPValidationErrorFromJSON,
  HTTPValidationErrorToJSON,
  RevokeApiKeyResponse,
  RevokeApiKeyResponseFromJSON,
  RevokeApiKeyResponseToJSON,
} from '../models';

export interface GetKeyApiKeysGetRequest {
  acceptLanguage?: string;
}

export interface RevokeApiKeysRevokePostRequest {
  acceptLanguage?: string;
}

/**
 *
 */
export class ApiKeysApi extends runtime.BaseAPI {
  /**
   * Get Key
   */
  async getKeyApiKeysGetRaw(
    requestParameters: GetKeyApiKeysGetRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<RevokeApiKeyResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      requestParameters.acceptLanguage !== undefined &&
      requestParameters.acceptLanguage !== null
    ) {
      headerParameters['accept-language'] = String(
        requestParameters.acceptLanguage
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('HTTPBearer', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api-keys/`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      RevokeApiKeyResponseFromJSON(jsonValue)
    );
  }

  /**
   * Get Key
   */
  async getKeyApiKeysGet(
    requestParameters: GetKeyApiKeysGetRequest,
    initOverrides?: RequestInit
  ): Promise<RevokeApiKeyResponse> {
    const response = await this.getKeyApiKeysGetRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Revoke
   */
  async revokeApiKeysRevokePostRaw(
    requestParameters: RevokeApiKeysRevokePostRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<RevokeApiKeyResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      requestParameters.acceptLanguage !== undefined &&
      requestParameters.acceptLanguage !== null
    ) {
      headerParameters['accept-language'] = String(
        requestParameters.acceptLanguage
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('HTTPBearer', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/api-keys/revoke`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      RevokeApiKeyResponseFromJSON(jsonValue)
    );
  }

  /**
   * Revoke
   */
  async revokeApiKeysRevokePost(
    requestParameters: RevokeApiKeysRevokePostRequest,
    initOverrides?: RequestInit
  ): Promise<RevokeApiKeyResponse> {
    const response = await this.revokeApiKeysRevokePostRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }
}
