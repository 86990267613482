/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { useIntl } from 'react-intl';

/* eslint-disable-next-line */
export interface LanguageSwitcherProps {}

export function LanguageSwitcher(props: LanguageSwitcherProps) {
  const { asPath, locale } = useRouter();
  const { formatMessage } = useIntl();
  useEffect(() => {
    if (locale == null) return;
    if (locale.indexOf('ar') === 0 && document.documentElement.dir !== 'rtl') {
      document.documentElement.dir = 'rtl';
    } else if (locale === 'en' && document.documentElement.dir !== 'ltr') {
      document.documentElement.dir = 'ltr';
    }
  }, [locale]);
  return (
    <Link href={asPath} locale={locale === 'en' ? 'ar' : 'en'}>
      <a
        aria-label={formatMessage({
          id: 'SWITCH_MESSAGE',
          defaultMessage: 'التحويل الى اللغة العربية',
        })}
        hrefLang={locale === 'en' ? 'ar' : 'en'}
        className="rounded-md px-2 py-3 flex items-center justify-center inline-end-2 hover:bg-gray-100 transition-colors"
      >
        {formatMessage({
          id: 'LANG_BADGE',
          defaultMessage: 'العربية',
        })}
      </a>
    </Link>
  );
}

export default LanguageSwitcher;
