/* tslint:disable */
/* eslint-disable */
/**
 * channels-com
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  CartItem,
  CartItemFromJSON,
  CartItemFromJSONTyped,
  CartItemToJSON,
} from './';

/**
 *
 * @export
 * @interface Cart
 */
export interface Cart {
  /**
   *
   * @type {number}
   * @memberof Cart
   */
  id: number;
  /**
   *
   * @type {Array<CartItem>}
   * @memberof Cart
   */
  items: Array<CartItem>;
  /**
   *
   * @type {boolean}
   * @memberof Cart
   */
  isActive: boolean;
  /**
   *
   * @type {number}
   * @memberof Cart
   */
  vat: number;
  /**
   *
   * @type {number}
   * @memberof Cart
   */
  subtotal: number;
  /**
   *
   * @type {number}
   * @memberof Cart
   */
  total: number;
}

export function CartFromJSON(json: any): Cart {
  return CartFromJSONTyped(json, false);
}

export function CartFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Cart {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    items: (json['items'] as Array<any>).map(CartItemFromJSON),
    isActive: json['is_active'],
    vat: json['vat'],
    subtotal: json['subtotal'],
    total: json['total'],
  };
}

export function CartToJSON(value?: Cart | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    items: (value.items as Array<any>).map(CartItemToJSON),
    is_active: value.isActive,
    total: value.total,
  };
}
