import React, { useEffect, useRef, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import Image from 'next/image';
import Link from 'next/link';
import { CartItem } from '../../../../httpClient/index';
/* eslint-disable-next-line */
export interface CartPopupProps {
  items?: CartItem[];
  currency: string;
  emptyMessage: string;
  gotoMessage: string;
}

export function CartPopup({
  items,
  currency,
  emptyMessage,
  gotoMessage,
}: CartPopupProps) {
  const [shaking, setShaking] = useState(false);
  const previous = useRef(items);
  useEffect(() => {
    if (items?.length && previous.current !== items) {
      setShaking(true);
      previous.current = items;
    }
  }, [items]);
  const items_len = items?.length;
  return (
    <Menu>
      {({ open }) => (
        <>
          <div>
            <Menu.Button
              type="button"
              className={`max-w-xs rounded-full flex items-center text-sm focus:outline-none relative p-2${
                shaking ? ' shake' : ''
              }`}
              id="cart-menu"
              aria-expanded="false"
              aria-haspopup="true"
              onAnimationEnd={() => {
                setShaking(false);
              }}
            >
              <span className="sr-only">Open Cart Menu menu</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="h-6 w-6 text-gray-600"
              >
                <path d="M6 2L3 6v14a2 2 0 002 2h14a2 2 0 002-2V6l-3-4zM3 6h18M16 10a4 4 0 01-8 0" />
              </svg>
              {(items ?? []).length > 0 ? (
                <span className="absolute bg-red-500 h-5 px-1.5 right-0 rounded-full text-white top-0">
                  {items?.length}
                </span>
              ) : null}
            </Menu.Button>
          </div>
          <Transition
            show={open}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
            className="max-h-screen overflow-y-scroll origin-top-right absolute inline-end-0 mt-2 w-full md:w-80 rounded-md shadow-lg py-1 bg-white bg-opacity-90 ring-1 ring-black ring-opacity-5 focus:outline-none z-10"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="cart-menu"
          >
            <Menu.Items static>
              <div>
                {items_len && (
                  <Menu.Item>
                    <div className="flex p-4">
                      <Link href="/cart">
                        <a className="bg-identity-primary flex flex-1 h-11 items-center justify-center rounded text-center text-identity-primary-inverted">
                          {gotoMessage}
                        </a>
                      </Link>
                    </div>
                  </Menu.Item>
                )}
              </div>
              {items && items.length > 0 ? (
                items.map((item) => (
                  <Menu.Item key={item.id}>
                    <div className="flex p-4">
                      <Link
                        href={{
                          pathname: '/card/[id]',
                          query: { id: item.cardId },
                        }}
                      >
                        <a className="relative shadow w-12 h-8">
                          <Image
                            src={item.image.replace(/\{|\}/gi, '')}
                            layout="fill"
                            unoptimized={
                              !item.image.includes('s3.amazonaws.com')
                            }
                            objectFit="fill"
                          />
                        </a>
                      </Link>
                      <div className="flex flex-col mis-2 flex-1">
                        <h3>{item.valueEn}</h3>
                        <h4>{`${item.quantity} ✕ ${item.price} ${currency}`}</h4>
                      </div>
                      <div>
                        {item.quantity * item.price} {currency}
                      </div>
                    </div>
                  </Menu.Item>
                ))
              ) : (
                <Menu.Item>
                  <div className="block px-4 py-2 h-24 text-sm text-gray-700 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-200 focus:ring-white">
                    {emptyMessage}
                  </div>
                </Menu.Item>
              )}
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
}

export default CartPopup;
