import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

export const useCurrency = () => {
  const { formatNumberToParts, formatNumber, locale } = useIntl();
  return useCallback(
    (value: bigint | number) => {
      // if (locale === 'en') return formatNumber(value, { format: 'currency' });
      const parts = formatNumberToParts(value, { format: 'currency' });
      const reducer = (acc: string, cur: Intl.NumberFormatPart) =>
        acc + cur.value;
      if (parts?.[0]?.type === 'currency') {
        const [currencyPart, spacePart] = parts.splice(0, 2);
        parts.push(spacePart, currencyPart);
        return parts.reduce(reducer, '');
      }
      const currencyPart = parts.find((p) => p.type === 'currency');
      if (currencyPart == null)
        return formatNumber(value, { format: 'currency' });
      return [
        ...parts.slice(0, parts.indexOf(currencyPart)),
        ...parts.slice(parts.indexOf(currencyPart) + 2),
        parts[parts.indexOf(currencyPart) + 1],
        currencyPart,
      ].reduce(reducer, '');
    },
    [locale]
  );
};
