/* tslint:disable */
/* eslint-disable */
/**
 * channels-com
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  BodyAddCartItemCartsItemPost,
  BodyAddCartItemCartsItemPostFromJSON,
  BodyAddCartItemCartsItemPostToJSON,
  BodyUpdateCartItemCartsItemPut,
  BodyUpdateCartItemCartsItemPutFromJSON,
  BodyUpdateCartItemCartsItemPutToJSON,
  CartItemResponse,
  CartItemResponseFromJSON,
  CartItemResponseToJSON,
  CartResponse,
  CartResponseFromJSON,
  CartResponseToJSON,
  HTTPValidationError,
  HTTPValidationErrorFromJSON,
  HTTPValidationErrorToJSON,
  OrderCartResponse,
  OrderCartResponseFromJSON,
  OrderCartResponseToJSON,
} from '../models';

export interface AddCartItemCartsItemPostRequest {
  bodyAddCartItemCartsItemPost: BodyAddCartItemCartsItemPost;
  acceptLanguage?: string;
}

export interface GetCartCartsGetRequest {
  acceptLanguage?: string;
}

export interface OrderCartCartsOrderPostRequest {
  acceptLanguage?: string;
}

export interface RemoveCartItemCartsItemIdDeleteRequest {
  id: number;
  acceptLanguage?: string;
}

export interface UpdateCartItemCartsItemPutRequest {
  bodyUpdateCartItemCartsItemPut: BodyUpdateCartItemCartsItemPut;
  acceptLanguage?: string;
}

/**
 *
 */
export class CartsApi extends runtime.BaseAPI {
  /**
   * Add Cart Item
   */
  async addCartItemCartsItemPostRaw(
    requestParameters: AddCartItemCartsItemPostRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<CartItemResponse>> {
    if (
      requestParameters.bodyAddCartItemCartsItemPost === null ||
      requestParameters.bodyAddCartItemCartsItemPost === undefined
    ) {
      throw new runtime.RequiredError(
        'bodyAddCartItemCartsItemPost',
        'Required parameter requestParameters.bodyAddCartItemCartsItemPost was null or undefined when calling addCartItemCartsItemPost.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      requestParameters.acceptLanguage !== undefined &&
      requestParameters.acceptLanguage !== null
    ) {
      headerParameters['accept-language'] = String(
        requestParameters.acceptLanguage
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('HTTPBearer', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/carts/item`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: BodyAddCartItemCartsItemPostToJSON(
          requestParameters.bodyAddCartItemCartsItemPost
        ),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CartItemResponseFromJSON(jsonValue)
    );
  }

  /**
   * Add Cart Item
   */
  async addCartItemCartsItemPost(
    requestParameters: AddCartItemCartsItemPostRequest,
    initOverrides?: RequestInit
  ): Promise<CartItemResponse> {
    const response = await this.addCartItemCartsItemPostRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Get Cart
   */
  async getCartCartsGetRaw(
    requestParameters: GetCartCartsGetRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<CartResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      requestParameters.acceptLanguage !== undefined &&
      requestParameters.acceptLanguage !== null
    ) {
      headerParameters['accept-language'] = String(
        requestParameters.acceptLanguage
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('HTTPBearer', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/carts/`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CartResponseFromJSON(jsonValue)
    );
  }

  /**
   * Get Cart
   */
  async getCartCartsGet(
    requestParameters: GetCartCartsGetRequest,
    initOverrides?: RequestInit
  ): Promise<CartResponse> {
    const response = await this.getCartCartsGetRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Turns cart into order - it checks if the channel is active. - it checks if the cart is valid - do create order request with order ms - deactivate cart - do complete order request with order ms - return pin codes
   * Order Cart
   */
  async orderCartCartsOrderPostRaw(
    requestParameters: OrderCartCartsOrderPostRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<OrderCartResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      requestParameters.acceptLanguage !== undefined &&
      requestParameters.acceptLanguage !== null
    ) {
      headerParameters['accept-language'] = String(
        requestParameters.acceptLanguage
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('HTTPBearer', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/carts/order`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      OrderCartResponseFromJSON(jsonValue)
    );
  }

  /**
   * Turns cart into order - it checks if the channel is active. - it checks if the cart is valid - do create order request with order ms - deactivate cart - do complete order request with order ms - return pin codes
   * Order Cart
   */
  async orderCartCartsOrderPost(
    requestParameters: OrderCartCartsOrderPostRequest,
    initOverrides?: RequestInit
  ): Promise<OrderCartResponse> {
    const response = await this.orderCartCartsOrderPostRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Remove Cart Item
   */
  async removeCartItemCartsItemIdDeleteRaw(
    requestParameters: RemoveCartItemCartsItemIdDeleteRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<CartItemResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling removeCartItemCartsItemIdDelete.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      requestParameters.acceptLanguage !== undefined &&
      requestParameters.acceptLanguage !== null
    ) {
      headerParameters['accept-language'] = String(
        requestParameters.acceptLanguage
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('HTTPBearer', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/carts/item/{id}`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CartItemResponseFromJSON(jsonValue)
    );
  }

  /**
   * Remove Cart Item
   */
  async removeCartItemCartsItemIdDelete(
    requestParameters: RemoveCartItemCartsItemIdDeleteRequest,
    initOverrides?: RequestInit
  ): Promise<CartItemResponse> {
    const response = await this.removeCartItemCartsItemIdDeleteRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * Update Cart Item
   */
  async updateCartItemCartsItemPutRaw(
    requestParameters: UpdateCartItemCartsItemPutRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<CartItemResponse>> {
    if (
      requestParameters.bodyUpdateCartItemCartsItemPut === null ||
      requestParameters.bodyUpdateCartItemCartsItemPut === undefined
    ) {
      throw new runtime.RequiredError(
        'bodyUpdateCartItemCartsItemPut',
        'Required parameter requestParameters.bodyUpdateCartItemCartsItemPut was null or undefined when calling updateCartItemCartsItemPut.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      requestParameters.acceptLanguage !== undefined &&
      requestParameters.acceptLanguage !== null
    ) {
      headerParameters['accept-language'] = String(
        requestParameters.acceptLanguage
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('HTTPBearer', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/carts/item`,
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: BodyUpdateCartItemCartsItemPutToJSON(
          requestParameters.bodyUpdateCartItemCartsItemPut
        ),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      CartItemResponseFromJSON(jsonValue)
    );
  }

  /**
   * Update Cart Item
   */
  async updateCartItemCartsItemPut(
    requestParameters: UpdateCartItemCartsItemPutRequest,
    initOverrides?: RequestInit
  ): Promise<CartItemResponse> {
    const response = await this.updateCartItemCartsItemPutRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }
}
