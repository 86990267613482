/* tslint:disable */
/* eslint-disable */
/**
 * channels-com
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  SingleOrder,
  SingleOrderFromJSON,
  SingleOrderFromJSONTyped,
  SingleOrderToJSON,
} from './';

/**
 *
 * @export
 * @interface SingleOrderResponse
 */
export interface SingleOrderResponse {
  /**
   *
   * @type {string}
   * @memberof SingleOrderResponse
   */
  message: string;
  /**
   *
   * @type {SingleOrder}
   * @memberof SingleOrderResponse
   */
  order: SingleOrder;
}

export function SingleOrderResponseFromJSON(json: any): SingleOrderResponse {
  return SingleOrderResponseFromJSONTyped(json, false);
}

export function SingleOrderResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SingleOrderResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    message: json['message'],
    order: SingleOrderFromJSON(json['order']),
  };
}

export function SingleOrderResponseToJSON(
  value?: SingleOrderResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    message: value.message,
    order: SingleOrderToJSON(value.order),
  };
}
