/* tslint:disable */
/* eslint-disable */
/**
 * channels-com
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface UserCreate
 */
export interface UserCreate {
  /**
   *
   * @type {string}
   * @memberof UserCreate
   */
  email: string;
  /**
   *
   * @type {number}
   * @memberof UserCreate
   */
  balanceMsReference?: number;
  /**
   *
   * @type {boolean}
   * @memberof UserCreate
   */
  isActive?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof UserCreate
   */
  isVerified?: boolean;
  /**
   *
   * @type {string}
   * @memberof UserCreate
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof UserCreate
   */
  phone: string;
  /**
   *
   * @type {number}
   * @memberof UserCreate
   */
  channelId?: number;
  /**
   *
   * @type {string}
   * @memberof UserCreate
   */
  channelName: string;
  /**
   *
   * @type {string}
   * @memberof UserCreate
   */
  password: string;
}

export function UserCreateFromJSON(json: any): UserCreate {
  return UserCreateFromJSONTyped(json, false);
}

export function UserCreateFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UserCreate {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    email: json['email'],
    balanceMsReference: !exists(json, 'balance_ms_reference')
      ? undefined
      : json['balance_ms_reference'],
    isActive: !exists(json, 'is_active') ? undefined : json['is_active'],
    isVerified: !exists(json, 'is_verified') ? undefined : json['is_verified'],
    name: json['name'],
    phone: json['phone'],
    channelId: !exists(json, 'channel_id') ? undefined : json['channel_id'],
    channelName: json['channel_name'],
    password: json['password'],
  };
}

export function UserCreateToJSON(value?: UserCreate | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    email: value.email,
    balance_ms_reference: value.balanceMsReference,
    is_active: value.isActive,
    is_verified: value.isVerified,
    name: value.name,
    phone: value.phone,
    channel_id: value.channelId,
    channel_name: value.channelName,
    password: value.password,
  };
}
