import React from 'react';

/* eslint-disable-next-line */
export interface StepperProps {
  value: number;
  max: number;
  min: number;
  onChange: (value: number) => void;
}

export function Stepper({ value, min, max, onChange }: StepperProps) {
  return (
    <div
      dir="ltr"
      className="rounded border-gray-400 border-solid border inline-block"
    >
      <button
        className="w-6 text-md"
        disabled={value >= max}
        onClick={() => {
          onChange(Math.min(max, value + 1));
        }}
        aria-label="add quantity"
      >
        +
      </button>
      <input
        pattern="\d+"
        className="w-12 text-md text-center px-2"
        value={value}
        onChange={(evt) => {
          onChange(
            Math.max(min, Math.min(max, parseInt(evt.target.value, 10) || min))
          );
        }}
      />
      <button
        className="w-6 text-md"
        disabled={value <= min}
        onClick={() => {
          onChange(Math.max(min, value - 1));
        }}
        aria-label="reduce quantity"
      >
        -
      </button>
    </div>
  );
}

export default Stepper;
