/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef, Fragment, useEffect } from 'react';
import Image, { ImageProps } from 'next/image';
import Link from 'next/link';
import LanguageSwitcher from '../language-switcher/language-switcher';
import CartPopup from '../cart-popup/cart-popup';
import { Disclosure, Menu, Popover, Transition } from '@headlessui/react';
import { CartItem, Category } from '../../../../httpClient/index';
/* eslint-disable-next-line */
export interface HeaderProps {
  items: Array<{ href: string; label: string }>;
  onLoginClick: () => void;
  onBalanceClick: () => void;
  onLogout: () => void;
  loggedIn: boolean;
  cartItems?: CartItem[];
  currency: string;
  balance: string;
  loginMessage: string;
  logoutMessage: string;
  emptyMessage: string;
  gotoMessage: string;
  profileNavItems: Array<{
    id: string;
    icon: (active?: boolean) => JSX.Element;
    path: string;
    title: string;
  }>;
  categoryMessage: string;
  categoryNavItems: Array<Category>;
  logo?: ImageProps;
  locale: string;
}

function NextLink(props: React.ComponentProps<'a'>) {
  const { href, children, ...rest } = props;
  return (
    <Link href={href!}>
      <a {...rest}>{children}</a>
    </Link>
  );
}

const ProfileMenu = ({
  onLogout,
  navItems,
  logoutMessage,
}: {
  onLogout: () => void;
  navItems: HeaderProps['profileNavItems'];
  logoutMessage: string;
}) => (
  <Menu as="div" className="relative flex-shrink-0 ml-4">
    {({ open }) => (
      <>
        <div>
          <Menu.Button className="rounded-md p-2 flex text-sm text-gray-600 hover:bg-gray-200 focus:outline-none focus:bg-light-blue-900 focus:ring-2 focus:ring-offset-2 focus:ring-offset-light-blue-900 focus:ring-white">
            <span className="sr-only">Open user menu</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="h-6 w-6"
            >
              <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2" />
              <circle cx="12" cy="7" r="4" />
            </svg>
          </Menu.Button>
        </div>
        <Transition
          show={open}
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 md:scale-95 translate-y-full md:translate-y-0"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 md:scale-95 translate-y-full md:translate-y-0"
        >
          <Menu.Items
            static
            className="fixed origin-bottom w-full block-end-0 shadow-upper md:block-end-auto md:origin-top-right md:absolute inline-end-0 mt-2 md:w-48 rounded-md md:shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-20 p-2 backdrop-blur backdrop-filter bg-opacity-90"
          >
            {navItems.map((item) => (
              <Menu.Item
                key={item.id}
                as={NextLink}
                href={item.path}
                className={({ active }) =>
                  `${
                    active ? 'bg-gray-100 ' : ''
                  }flex p-2 text-sm text-gray-700 rounded`
                }
              >
                {item.icon()} <span className="mis-2">{item.title}</span>
              </Menu.Item>
            ))}
            <Menu.Item>
              {({ active }) => (
                <button
                  className={`${active ? 'bg-gray-100' : ''}
                      block py-2 px-4 text-sm w-full bg-identity-primary text-identity-primary-inverted rounded-md mt-1`}
                  onClick={onLogout}
                >
                  {logoutMessage}
                </button>
              )}
            </Menu.Item>
          </Menu.Items>
        </Transition>
      </>
    )}
  </Menu>
);

export function Header({
  items,
  onLoginClick,
  onBalanceClick,
  loggedIn,
  cartItems,
  currency,
  balance,
  onLogout,
  profileNavItems,
  loginMessage,
  logoutMessage,
  emptyMessage,
  gotoMessage,
  categoryMessage,
  categoryNavItems,
  logo,
  locale,
}: HeaderProps) {
  const ref = useRef(null);
  const [delayedLoggedIn, setDelayedLoggedIn] = useState(false);
  useEffect(() => {
    setDelayedLoggedIn(loggedIn);
  }, [loggedIn]);
  return (
    <Disclosure as="nav" className="shadow-lg sticky top-0 z-10">
      {({ open }) => (
        <>
          <div className=" max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8 backdrop-blur backdrop-filter bg-opacity-60 z-1 bg-white">
            <div className="flex items-center justify-between h-20">
              <div className="flex items-center">
                <div className="-mis-2 flex md:hidden">
                  {/* <!-- Mobile menu button --> */}
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-300 focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    <svg
                      className={`${open ? 'hidden' : 'block'} h-6 w-6`}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M4 6h16M4 12h16M4 18h16"
                      />
                    </svg>
                    <svg
                      className={`${open ? 'block' : 'hidden'} h-6 w-6`}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </Disclosure.Button>
                </div>
                <Link href="/">
                  <a className="relative block flex-shrink-0 mis-2 md:mis-0">
                    <Image
                      src="/channels.svg"
                      alt="Resal channels"
                      width={250}
                      height={150}
                      layout="intrinsic"
                      priority
                      unoptimized
                    />
                  </a>
                </Link>
                <Popover className="hidden md:block mis-10">
                  {({ open }) => (
                    <>
                      <Popover.Button
                        className={`${
                          open ? 'text-gray-900' : 'text-gray-500'
                        } group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-identity-primary px-3 py-2 bg-opacity-70`}
                      >
                        {categoryMessage}
                      </Popover.Button>
                      <Transition
                        show={open}
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                      >
                        <Popover.Panel
                          static
                          className="absolute z-10 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-md sm:px-0 lg:max-w-3xl"
                        >
                          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                            <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8 grid-cols-3">
                              {categoryNavItems.map((item) => (
                                <Link
                                  key={item.id}
                                  href={`/category/${item.id}`}
                                >
                                  <a className="text-base text-rgray hover:bg-gray-100 px-3 py-2 rounded-md font-medium bg-white bg-opacity-70">
                                    {locale === 'ar'
                                      ? item.nameAr
                                      : item.nameEn}
                                  </a>
                                </Link>
                              ))}
                            </div>
                          </div>
                        </Popover.Panel>
                      </Transition>
                    </>
                  )}
                </Popover>
                <div className="hidden md:block">
                  <div className="mis-10 flex items-baseline space-x-4">
                    {items.map(({ label, href }) => (
                      <Link href={href} key={href}>
                        <a className="text-base text-rgray hover:bg-gray-100 px-3 py-2 rounded-md font-medium bg-white bg-opacity-70">
                          {label}
                        </a>
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
              <div className="md:hidden">
                <CartPopup
                  items={cartItems}
                  currency={currency}
                  emptyMessage={emptyMessage}
                  gotoMessage={gotoMessage}
                />
              </div>
              <div className="hidden md:block">
                <div className="mis-4 flex items-center md:mis-6">
                  {delayedLoggedIn ? (
                    <button
                      className="bg-identity-tertiary inline-block mie-4 px-4 py-2 rounded-md text-sm text-identity-tertiary-inverted"
                      style={{ unicodeBidi: 'plaintext' }}
                      onClick={onBalanceClick}
                    >
                      {balance}
                    </button>
                  ) : null}
                  {delayedLoggedIn ? (
                    <ProfileMenu
                      onLogout={onLogout}
                      navItems={profileNavItems}
                      logoutMessage={logoutMessage}
                    />
                  ) : (
                    <button
                      id="show_login_menu"
                      className="flex p-2 rounded-md text-gray-600 hover:bg-gray-200 focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-offset-identity-primary focus:ring-white"
                      onClick={onLoginClick}
                    >
                      {delayedLoggedIn ? null : (
                        <span className="mie-1">{loginMessage}</span>
                      )}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="h-6 w-6"
                      >
                        <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2" />
                        <circle cx="12" cy="7" r="4" />
                      </svg>
                    </button>
                  )}
                  <div className="mis-3 relative">
                    <CartPopup
                      items={cartItems}
                      currency={currency}
                      emptyMessage={emptyMessage}
                      gotoMessage={gotoMessage}
                    />
                  </div>
                  <div className="mis-3">
                    <LanguageSwitcher />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Transition
            show={open}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
            className="absolute inline-end-0 mt-0 w-full md:w-48 rounded-md rounded-t-none shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none md:hidden z-20 origin-top"
          >
            <div className="relative py-1 ">
              <div className="absolute inset-0 bg-opacity-90 bg-white" />
              {/* <!-- Mobile menu, show/hide based on menu state. --> */}
              <div className="md:hidden relative" id="mobile-menu" ref={ref}>
                <Popover className="px-2 pt-4 pb-0 sm:px-3">
                  {({ open }) => (
                    <>
                      <Popover.Button className="text-gray-900 hover:bg-gray-100 text-sm group rounded-md inline-flex items-center font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-identity-primary px-3 py-2 bg-opacity-70">
                        {categoryMessage}
                      </Popover.Button>
                      <Transition show={open}>
                        <Transition.Child
                          enter="transition ease-out duration-200"
                          enterFrom="opacity-0"
                          enterTo="opacity-100"
                          leave="transition ease-in duration-150"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Popover.Overlay className="bg-black fixed inset-0 opacity-10" />
                        </Transition.Child>
                        <Transition.Child
                          as={Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="opacity-0 translate-y-1"
                          enterTo="opacity-100 translate-y-0"
                          leave="transition ease-in duration-150"
                          leaveFrom="opacity-100 translate-y-0"
                          leaveTo="opacity-0 translate-y-1"
                        >
                          <Popover.Panel
                            static
                            className="fixed block-end-20 z-10 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-md sm:px-0 lg:max-w-3xl"
                          >
                            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                              <div
                                className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8 grid-cols-3 overflow-y-auto"
                                style={{ maxHeight: '80vh' }}
                              >
                                {categoryNavItems.map((item) => (
                                  <Link
                                    key={item.id}
                                    href={`/category/${item.id}`}
                                  >
                                    <a className="text-sm text-rgray hover:bg-gray-100 px-3 py-2 rounded-md font-medium bg-white bg-opacity-70">
                                      {locale == 'ar'
                                        ? item.nameAr
                                        : item.nameEn}
                                    </a>
                                  </Link>
                                ))}
                              </div>
                            </div>
                          </Popover.Panel>
                        </Transition.Child>
                      </Transition>
                    </>
                  )}
                </Popover>
                {items.length > 0 ? (
                  <div className="px-2 pt-4 pb-3 space-y-1 sm:px-3">
                    {items.map(({ label, href }) => (
                      <Link href={href} key={href}>
                        <a className="text-gray-900 hover:bg-gray-100 px-3 py-2 rounded-md text-sm font-medium">
                          {label}
                        </a>
                      </Link>
                    ))}
                  </div>
                ) : null}
                <div
                  className={`pt-4 pb-3${
                    items.length > 0 ? ' border-t border-gray-200' : ''
                  }`}
                >
                  <div className="flex items-center px-5 justify-between">
                    {delayedLoggedIn ? (
                      <ProfileMenu
                        onLogout={onLogout}
                        navItems={profileNavItems}
                        logoutMessage={logoutMessage}
                      />
                    ) : (
                      <button
                        className="flex p-1 rounded-md text-gray-600 hover:bg-gray-200 focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                        onClick={onLoginClick}
                      >
                        {delayedLoggedIn ? null : (
                          <span className="mie-1">{loginMessage}</span>
                        )}

                        {/* <!-- Heroicon name: outline/bell --> */}
                        <svg
                          className="h-6 w-6"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                          />
                        </svg>
                      </button>
                    )}
                    <div>
                      <LanguageSwitcher />
                    </div>
                  </div>
                </div>
                {delayedLoggedIn ? (
                  <div className="pt-4 pb-3 border-t border-gray-200 px-5">
                    <button
                      className="hover:bg-gray-200 focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white p-2 rounded-md"
                      onClick={onLogout}
                    >
                      {logoutMessage}
                    </button>
                  </div>
                ) : null}
              </div>
            </div>
          </Transition>
        </>
      )}
    </Disclosure>
  );
}

export default Header;
