/* tslint:disable */
/* eslint-disable */
/**
 * channels-com
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface BodyVerifyWihTokenUsersActivatePost
 */
export interface BodyVerifyWihTokenUsersActivatePost {
  /**
   *
   * @type {string}
   * @memberof BodyVerifyWihTokenUsersActivatePost
   */
  token: string;
}

export function BodyVerifyWihTokenUsersActivatePostFromJSON(
  json: any
): BodyVerifyWihTokenUsersActivatePost {
  return BodyVerifyWihTokenUsersActivatePostFromJSONTyped(json, false);
}

export function BodyVerifyWihTokenUsersActivatePostFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): BodyVerifyWihTokenUsersActivatePost {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    token: json['token'],
  };
}

export function BodyVerifyWihTokenUsersActivatePostToJSON(
  value?: BodyVerifyWihTokenUsersActivatePost | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    token: value.token,
  };
}
