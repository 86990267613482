import Image from 'next/image';
import React from 'react';

/* eslint-disable-next-line */
export interface TagButtonProps {
  title: string;
  onClose: () => void;
  flag?: string;
  className?: string;
}

export function TagButton({ title, onClose, flag, className }: TagButtonProps) {
  console.log('flag', flag);
  return (
    <span
      className={`inline-flex rounded items-center py-2 pis-2.5 pie-1 text-sm font-regular bg-identity-tertiary text-identity-tertiary-inverted ring-1 ring-identity-primary ${
        className ?? ''
      }`}
    >
      {flag ? (
        <Image width={18} height={15} layout="fixed" src={flag} unoptimized />
      ) : null}
      <span className={flag ? 'mis-2' : undefined}>{title}</span>
      <button
        type="button"
        className="flex-shrink-0 mis-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-identity-primary focus:outline-none"
        onClick={onClose}
      >
        <span className="sr-only">Remove {title} option</span>
        <svg
          className="h-2 w-2"
          stroke="currentColor"
          fill="none"
          viewBox="0 0 8 8"
        >
          <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
        </svg>
      </button>
    </span>
  );
}

export default TagButton;
