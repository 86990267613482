/* tslint:disable */
/* eslint-disable */
/**
 * channels-com
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  RedemptionCard,
  RedemptionCardFromJSON,
  RedemptionCardFromJSONTyped,
  RedemptionCardToJSON,
} from './';

/**
 *
 * @export
 * @interface OrderCartResponseData
 */
export interface OrderCartResponseData {
  /**
   *
   * @type {string}
   * @memberof OrderCartResponseData
   */
  id: string;
  /**
   *
   * @type {number}
   * @memberof OrderCartResponseData
   */
  cartId: number;
  /**
   *
   * @type {Array<RedemptionCard>}
   * @memberof OrderCartResponseData
   */
  redemptionCards: Array<RedemptionCard>;
}

export function OrderCartResponseDataFromJSON(
  json: any
): OrderCartResponseData {
  return OrderCartResponseDataFromJSONTyped(json, false);
}

export function OrderCartResponseDataFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): OrderCartResponseData {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    cartId: json['cart_id'],
    redemptionCards: (json['redemption_cards'] as Array<any>).map(
      RedemptionCardFromJSON
    ),
  };
}

export function OrderCartResponseDataToJSON(
  value?: OrderCartResponseData | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    cart_id: value.cartId,
    redemption_cards: (value.redemptionCards as Array<any>).map(
      RedemptionCardToJSON
    ),
  };
}
