import create from 'zustand';
import { combine } from 'zustand/middleware';
import { CartItem } from '@httpclient';

const safeParse = (str: string) => {
  try {
    return JSON.parse(str);
  } catch {
    return undefined;
  }
};

const getDefault = (): {
  id: string;
  created?: Date;
  items?: Array<CartItem>;
} => {
  if (typeof window !== 'undefined') {
    try {
      let created: Date | undefined = undefined;
      const createdString = sessionStorage.getItem('tmp_crt_created');
      if (createdString != null && createdString !== '') {
        const parsed = safeParse(createdString);
        if (parsed != null) {
          created = new Date(parsed);
        }
      }
      return {
        id: sessionStorage.getItem('tmp_crt') || '',
        created,
        items: JSON.parse(sessionStorage.getItem('tmp_crt_items') || 'null'),
      };
    } catch {
      return {
        id: '',
        items: [],
      };
    }
  }
  return {
    id: '',
    items: [],
  };
};

export const CartStore = create(
  combine(getDefault(), (set, get) => ({
    addItem(item: CartItem) {
      const { id, items } = get();
      if (id === '' || items == null) {
        const created = new Date();
        sessionStorage.setItem('tmp_crt_created', JSON.stringify(created));
        const items = [item];
        sessionStorage.setItem('tmp_crt_items', JSON.stringify(items));
        set({
          id: '__',
          created,
          items,
        });
      } else {
        let currentItems = items.slice(0);
        // TODO: check for stale carts
        if (items.some((existing) => existing.id === item.id)) {
          // we have an item with the same id
          // update the quatities
          currentItems = currentItems.map((existing) => {
            if (existing.id === item.id) {
              return item;
            }
            return existing;
          });
        } else {
          currentItems.push(item);
        }
        sessionStorage.setItem('tmp_crt_items', JSON.stringify(items));
        set({
          items: currentItems,
        });
      }
    },
    removeItem(id: string | string[]) {
      const { items } = get();
      if (items == null) return;
      const newItems = items.filter((item) =>
        Array.isArray(id) ? !id.includes(`${item.id}`) : `${item.id}` !== id
      );
      if (newItems.length !== items.length) {
        sessionStorage.setItem('tmp_crt_items', JSON.stringify(newItems));
        set({
          items: newItems,
        });
      }
    },
    clear() {
      sessionStorage.removeItem('tmp_crt_items');
      sessionStorage.removeItem('tmp_crt');
      set({
        id: '',
        items: [],
      });
    },
    stash(cart: {
      id: string;
      items: CartItem[];
      service_fees: number;
      totalWithVat: number;
    }) {
      sessionStorage.setItem('cart_stash', JSON.stringify(cart));
    },
    unstash() {
      try {
        const data = JSON.parse(
          sessionStorage.getItem('cart_stash') || '{"id":"", items:[]}'
        );
        // sessionStorage.removeItem('cart_stash');
        return data;
      } catch (e) {
        return {
          id: '',
          items: [],
          totalWithVat: 0,
          service_fees: 0,
        };
      }
    },
  }))
);
