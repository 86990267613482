/* tslint:disable */
/* eslint-disable */
/**
 * channels-com
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  HTTPValidationError,
  HTTPValidationErrorFromJSON,
  HTTPValidationErrorToJSON,
  Items,
  ItemsFromJSON,
  ItemsToJSON,
  ItemsResponse,
  ItemsResponseFromJSON,
  ItemsResponseToJSON,
} from '../models';

export interface StoreItemsStatItemsStatPostRequest {
  items: Items;
  acceptLanguage?: string;
}

/**
 *
 */
export class ItemsStatApi extends runtime.BaseAPI {
  /**
   * Store Items Stat
   */
  async storeItemsStatItemsStatPostRaw(
    requestParameters: StoreItemsStatItemsStatPostRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<ItemsResponse>> {
    if (
      requestParameters.items === null ||
      requestParameters.items === undefined
    ) {
      throw new runtime.RequiredError(
        'items',
        'Required parameter requestParameters.items was null or undefined when calling storeItemsStatItemsStatPost.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      requestParameters.acceptLanguage !== undefined &&
      requestParameters.acceptLanguage !== null
    ) {
      headerParameters['accept-language'] = String(
        requestParameters.acceptLanguage
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('HTTPBearer', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/items-stat/`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: ItemsToJSON(requestParameters.items),
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ItemsResponseFromJSON(jsonValue)
    );
  }

  /**
   * Store Items Stat
   */
  async storeItemsStatItemsStatPost(
    requestParameters: StoreItemsStatItemsStatPostRequest,
    initOverrides?: RequestInit
  ): Promise<ItemsResponse> {
    const response = await this.storeItemsStatItemsStatPostRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }
}
