import React from 'react';
export const CountryList = () => {
  return (
    <>
      <option value="966">SA +966</option>
      <option value="1">USA +1</option>
      <option value="971">AE +971</option>
      <option value="974">QA +974</option>
      <option value="965">KW +965</option>
      <option value="973">BH +973</option>
      <option value="968">OM +968</option>
      <option value="967">YE +967</option>
      <option value="20">EGY +20</option>
      <option value="249">SU +249</option>
      <option value="218">LY +218</option>
      <option value="216">TU +216</option>
      <option value="212">MO +212</option>
      <option value="213">ALG +213</option>
      <option value="961">LEB +961</option>
      <option value="962">JO +962</option>
      <option value="963">SYR +963</option>
      <option value="964">IRQ +964</option>
      <option value="970">PAL +970</option>
    </>
  );
};
