/* eslint-disable-next-line */
import React from 'react';
import { classNames } from '@resal-frontend/utils';

export interface WrapperProps {
  children: React.ReactNode;
  label: string;
  name: string;
  error?: string;
  className?: string;
}

export const InputWrapper = ({
  children,
  label,
  name,
  error,
  className,
}: WrapperProps) => {
  return (
    <>
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      <div className={classNames('relative', className || '')}>
        {children}
        {error ? (
          <div className="absolute inset-y-0 inline-end-0 pie-3 flex items-center pointer-events-none">
            <svg
              className="h-5 w-5 text-red-500"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                clipRule="evenodd"
              />
            </svg>
          </div>
        ) : null}
      </div>
      {error ? <p className="mt-2 text-sm text-red-600">{error}</p> : null}
    </>
  );
};
