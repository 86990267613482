import { ReactNode } from 'react';

/* eslint-disable-next-line */
export interface SplitLayoutProps {
  children: ReactNode;
  title?: string;
  footer?: ReactNode;
}

export function SplitLayout(props: SplitLayoutProps) {
  return (
    <div className="flex min-h-screen">
      <div className="flex-1" />
      <div className="max-w-screen-xl w-full grid grid-cols-5">
        <div className="col-span-3 flex flex-col items-center pie-2">
          <div className="my-auto">{props.children}</div>
          <footer className="mb-0 text-center">{props.footer}</footer>
        </div>
        <div className="col-span-2 bg-identity-primary flex justify-center items-center">
          <h1 className="text-identity-primary-inverted">{props.title}</h1>
        </div>
      </div>
      <div className="flex-1 bg-identity-primary" />
    </div>
  );
}

export default SplitLayout;
