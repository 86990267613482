/* tslint:disable */
/* eslint-disable */
/**
 * channels-com
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface Meta
 */
export interface Meta {
  /**
   *
   * @type {number}
   * @memberof Meta
   */
  total: number;
  /**
   *
   * @type {number}
   * @memberof Meta
   */
  perPage: number;
  /**
   *
   * @type {number}
   * @memberof Meta
   */
  currentPage: number;
  /**
   *
   * @type {number}
   * @memberof Meta
   */
  lastPage: number;
  /**
   *
   * @type {number}
   * @memberof Meta
   */
  from: number;
  /**
   *
   * @type {number}
   * @memberof Meta
   */
  to: number;
}

export function MetaFromJSON(json: any): Meta {
  return MetaFromJSONTyped(json, false);
}

export function MetaFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Meta {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    total: json['total'],
    perPage: json['per_page'],
    currentPage: json['current_page'],
    lastPage: json['last_page'],
    from: json['from'],
    to: json['to'],
  };
}

export function MetaToJSON(value?: Meta | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    total: value.total,
    per_page: value.perPage,
    current_page: value.currentPage,
    last_page: value.lastPage,
    from: value.from,
    to: value.to,
  };
}
