/* tslint:disable */
/* eslint-disable */
/**
 * channels-com
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import { Market, MarketFromJSON, MarketFromJSONTyped, MarketToJSON } from './';

/**
 *
 * @export
 * @interface ChannelDenomination
 */
export interface ChannelDenomination {
  /**
   *
   * @type {number}
   * @memberof ChannelDenomination
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof ChannelDenomination
   */
  channelId: number;
  /**
   *
   * @type {boolean}
   * @memberof ChannelDenomination
   */
  isActive: boolean;
  /**
   *
   * @type {string}
   * @memberof ChannelDenomination
   */
  image: string;
  /**
   *
   * @type {number}
   * @memberof ChannelDenomination
   */
  price: number;
  /**
   *
   * @type {string}
   * @memberof ChannelDenomination
   */
  valueAr: string;
  /**
   *
   * @type {string}
   * @memberof ChannelDenomination
   */
  valueEn: string;
  /**
   *
   * @type {number}
   * @memberof ChannelDenomination
   */
  serviceFees: number;
  /**
   *
   * @type {number}
   * @memberof ChannelDenomination
   */
  partnerFees: number;
  /**
   *
   * @type {number}
   * @memberof ChannelDenomination
   */
  quantity: number;
  /**
   *
   * @type {boolean}
   * @memberof ChannelDenomination
   */
  barcode: boolean;
  /**
   *
   * @type {Market}
   * @memberof ChannelDenomination
   */
  country?: Market;
  /**
   *
   * @type {Date}
   * @memberof ChannelDenomination
   */
  createdAt: Date;
  /**
   *
   * @type {Date}
   * @memberof ChannelDenomination
   */
  updatedAt: Date;
}

export function ChannelDenominationFromJSON(json: any): ChannelDenomination {
  return ChannelDenominationFromJSONTyped(json, false);
}

export function ChannelDenominationFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ChannelDenomination {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    channelId: json['channel_id'],
    isActive: json['is_active'],
    image: json['image'],
    price: json['price'],
    valueAr: json['value_ar'],
    valueEn: json['value_en'],
    serviceFees: json['service_fees'],
    partnerFees: json['partner_fees'],
    quantity: json['quantity'],
    barcode: json['barcode'],
    country: !exists(json, 'country')
      ? undefined
      : MarketFromJSON(json['country']),
    createdAt: new Date(json['created_at']),
    updatedAt: new Date(json['updated_at']),
  };
}

export function ChannelDenominationToJSON(
  value?: ChannelDenomination | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    channel_id: value.channelId,
    is_active: value.isActive,
    image: value.image,
    price: value.price,
    value_ar: value.valueAr,
    value_en: value.valueEn,
    service_fees: value.serviceFees,
    partner_fees: value.partnerFees,
    quantity: value.quantity,
    barcode: value.barcode,
    country: MarketToJSON(value.country),
    created_at: value.createdAt.toISOString(),
    updated_at: value.updatedAt.toISOString(),
  };
}
