/* tslint:disable */
/* eslint-disable */
/**
 * channels-com
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface Channel
 */
export interface Channel {
  /**
   *
   * @type {number}
   * @memberof Channel
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof Channel
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof Channel
   */
  balance: number;
  /**
   *
   * @type {number}
   * @memberof Channel
   */
  maxDailyTransactionAmount: number;
  /**
   *
   * @type {boolean}
   * @memberof Channel
   */
  isApi: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Channel
   */
  isActive: boolean;
  /**
   *
   * @type {number}
   * @memberof Channel
   */
  minBalanceAlertValue?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof Channel
   */
  minBalanceAlertEmails?: Array<string>;
}

export function ChannelFromJSON(json: any): Channel {
  return ChannelFromJSONTyped(json, false);
}

export function ChannelFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Channel {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    name: json['name'],
    balance: json['balance'],
    maxDailyTransactionAmount: json['max_daily_transaction_amount'],
    isApi: json['is_api'],
    isActive: json['is_active'],
    minBalanceAlertValue: !exists(json, 'min_balance_alert_value')
      ? undefined
      : json['min_balance_alert_value'],
    minBalanceAlertEmails: !exists(json, 'min_balance_alert_emails')
      ? undefined
      : json['min_balance_alert_emails'],
  };
}

export function ChannelToJSON(value?: Channel | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    balance: value.balance,
    max_daily_transaction_amount: value.maxDailyTransactionAmount,
    is_api: value.isApi,
    is_active: value.isActive,
    min_balance_alert_value: value.minBalanceAlertValue,
    min_balance_alert_emails: value.minBalanceAlertEmails,
  };
}
