import {
  UsersApi,
  ApiKeysApi,
  ItemsStatApi,
  CartsApi,
  CategoriesApi,
  MarketsApi,
  Configuration,
  ChannelCardsApi,
  OrdersApi,
} from '@httpclient';
import { useTokenStore } from '@stores';

export const usersApi = new UsersApi(
  new Configuration({
    basePath: process.env.NEXT_PUBLIC_CHANNELS_API,
    accessToken: () => useTokenStore.getState().accessToken,
  })
);

export const keysApi = new ApiKeysApi(
  new Configuration({
    basePath: process.env.NEXT_PUBLIC_CHANNELS_API,
    accessToken: () => useTokenStore.getState().accessToken,
  })
);

export const itemsStatApi = new ItemsStatApi(
  new Configuration({
    basePath: process.env.NEXT_PUBLIC_CHANNELS_API,
    accessToken: () => useTokenStore.getState().accessToken,
  })
);

export const cartsApi = new CartsApi(
  new Configuration({
    basePath: process.env.NEXT_PUBLIC_CHANNELS_API,
    accessToken: () => useTokenStore.getState().accessToken,
  })
);

export const categoriesApi = new CategoriesApi(
  new Configuration({
    basePath: process.env.NEXT_PUBLIC_CHANNELS_API,
    accessToken: () => useTokenStore.getState().accessToken,
  })
);

export const marketsApi = new MarketsApi(
  new Configuration({
    basePath: process.env.NEXT_PUBLIC_CHANNELS_API,
    accessToken: () => useTokenStore.getState().accessToken,
  })
);

export const channelCardsApi = new ChannelCardsApi(
  new Configuration({
    basePath: process.env.NEXT_PUBLIC_CHANNELS_API,
    accessToken: () => useTokenStore.getState().accessToken,
  })
);
export const ordersApi = new OrdersApi(
  new Configuration({
    basePath: process.env.NEXT_PUBLIC_CHANNELS_API,
    accessToken: () => useTokenStore.getState().accessToken,
  })
);
