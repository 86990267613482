/* tslint:disable */
/* eslint-disable */
/**
 * channels-com
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  Category,
  CategoryFromJSON,
  CategoryFromJSONTyped,
  CategoryToJSON,
} from './';

/**
 *
 * @export
 * @interface GetCategoryResponse
 */
export interface GetCategoryResponse {
  /**
   *
   * @type {string}
   * @memberof GetCategoryResponse
   */
  message: string;
  /**
   *
   * @type {Category}
   * @memberof GetCategoryResponse
   */
  category: Category;
}

export function GetCategoryResponseFromJSON(json: any): GetCategoryResponse {
  return GetCategoryResponseFromJSONTyped(json, false);
}

export function GetCategoryResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): GetCategoryResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    message: json['message'],
    category: CategoryFromJSON(json['category']),
  };
}

export function GetCategoryResponseToJSON(
  value?: GetCategoryResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    message: value.message,
    category: CategoryToJSON(value.category),
  };
}
