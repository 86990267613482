/* tslint:disable */
/* eslint-disable */
/**
 * channels-com
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  GetCategoryResponse,
  GetCategoryResponseFromJSON,
  GetCategoryResponseToJSON,
  HTTPValidationError,
  HTTPValidationErrorFromJSON,
  HTTPValidationErrorToJSON,
  ListCategoriesResponse,
  ListCategoriesResponseFromJSON,
  ListCategoriesResponseToJSON,
} from '../models';

export interface GetCategoryCategoriesIdGetRequest {
  id: number;
  acceptLanguage?: string;
}

export interface ListCategoriesCategoriesGetRequest {
  acceptLanguage?: string;
}

/**
 *
 */
export class CategoriesApi extends runtime.BaseAPI {
  /**
   * Get Category
   */
  async getCategoryCategoriesIdGetRaw(
    requestParameters: GetCategoryCategoriesIdGetRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<GetCategoryResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling getCategoryCategoriesIdGet.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      requestParameters.acceptLanguage !== undefined &&
      requestParameters.acceptLanguage !== null
    ) {
      headerParameters['accept-language'] = String(
        requestParameters.acceptLanguage
      );
    }

    const response = await this.request(
      {
        path: `/categories/{id}`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      GetCategoryResponseFromJSON(jsonValue)
    );
  }

  /**
   * Get Category
   */
  async getCategoryCategoriesIdGet(
    requestParameters: GetCategoryCategoriesIdGetRequest,
    initOverrides?: RequestInit
  ): Promise<GetCategoryResponse> {
    const response = await this.getCategoryCategoriesIdGetRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * List Categories
   */
  async listCategoriesCategoriesGetRaw(
    requestParameters: ListCategoriesCategoriesGetRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<ListCategoriesResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      requestParameters.acceptLanguage !== undefined &&
      requestParameters.acceptLanguage !== null
    ) {
      headerParameters['accept-language'] = String(
        requestParameters.acceptLanguage
      );
    }

    const response = await this.request(
      {
        path: `/categories/`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ListCategoriesResponseFromJSON(jsonValue)
    );
  }

  /**
   * List Categories
   */
  async listCategoriesCategoriesGet(
    requestParameters: ListCategoriesCategoriesGetRequest,
    initOverrides?: RequestInit
  ): Promise<ListCategoriesResponse> {
    const response = await this.listCategoriesCategoriesGetRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }
}
