/* tslint:disable */
/* eslint-disable */
/**
 * channels-com
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import { Market, MarketFromJSON, MarketFromJSONTyped, MarketToJSON } from './';

/**
 *
 * @export
 * @interface ListMarketsResponse
 */
export interface ListMarketsResponse {
  /**
   *
   * @type {string}
   * @memberof ListMarketsResponse
   */
  message: string;
  /**
   *
   * @type {Array<Market>}
   * @memberof ListMarketsResponse
   */
  markets: Array<Market>;
}

export function ListMarketsResponseFromJSON(json: any): ListMarketsResponse {
  return ListMarketsResponseFromJSONTyped(json, false);
}

export function ListMarketsResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ListMarketsResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    message: json['message'],
    markets: (json['markets'] as Array<any>).map(MarketFromJSON),
  };
}

export function ListMarketsResponseToJSON(
  value?: ListMarketsResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    message: value.message,
    markets: (value.markets as Array<any>).map(MarketToJSON),
  };
}
