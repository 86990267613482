import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { IntlShape, useIntl } from 'react-intl';
import { z } from 'zod';
import { InputWrapper } from '@resal-frontend/ui/index';
import messages from './messages';
import { useAlerts } from '@resal-frontend/ui/index';
import { usersApi } from '../../utils/api';
import { useMutation } from 'react-query';
import { RecoverPasswordUsersPasswordRecoveryPostRequest } from '@httpclient';

interface ForgotPasswordProps {
  login: () => void;
}

const schema = (formatMessage: IntlShape['formatMessage']) =>
  z.object({
    email: z.string().email(formatMessage(messages.invalidEmail)),
  });

export const ForgotPassword = ({ login }: ForgotPasswordProps) => {
  const { formatMessage, locale } = useIntl();
  const { register, handleSubmit, errors } = useForm({
    resolver: zodResolver(schema(formatMessage)),
  });
  const { addAlert } = useAlerts();

  const { mutate, isLoading } = useMutation(
    (args: RecoverPasswordUsersPasswordRecoveryPostRequest) =>
      usersApi.recoverPasswordUsersPasswordRecoveryPost(args),
    {
      onSuccess: (data) => {
        addAlert({ message: data.message });
      },
      onError: async (error: any) => {
        error = await error.json();
        addAlert({ message: error.detail });
      },
    }
  );
  return (
    <>
      <h1 className="font-medium mt-8 header-text">
        {formatMessage(messages.forgotPassword)}
      </h1>
      <p className="mt-2">{formatMessage(messages.forgotPasswordMessage)}</p>
      <form
        className="space-y-4 mt-3"
        onSubmit={handleSubmit((d) => {
          try {
            mutate({
              recoverEmail: {
                email: d.email,
              },
              acceptLanguage: locale,
            });
          } catch (e) {
            console.log('error', e);
          }
        })}
      >
        <div>
          <InputWrapper
            label={formatMessage(messages.email)}
            name="email"
            error={errors?.email?.message}
          >
            <input
              dir="ltr"
              type="email"
              name="email"
              id="email"
              placeholder="user@example.com"
              className="focus:ring-identity-primary focus:border-identity-primary block w-full sm:text-sm border-gray-300 rounded-md text-left mt-1"
              ref={register}
            />
          </InputWrapper>
        </div>
        <div>
          <button className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-identity-primary-inverted bg-identity-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-identity-primary">
            {formatMessage(messages.resetPassword)}
          </button>
        </div>
      </form>
      <button className="text-identity-primary mt-2" onClick={login}>
        {formatMessage(messages.backToLogin)}
      </button>
    </>
  );
};
