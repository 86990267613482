/* tslint:disable */
/* eslint-disable */
/**
 * channels-com
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  ChannelCardResponse,
  ChannelCardResponseFromJSON,
  ChannelCardResponseToJSON,
  ChannelCardsPaginatedResponse,
  ChannelCardsPaginatedResponseFromJSON,
  ChannelCardsPaginatedResponseToJSON,
  HTTPValidationError,
  HTTPValidationErrorFromJSON,
  HTTPValidationErrorToJSON,
} from '../models';

export interface GetChannelCardChannelCardsIdGetRequest {
  id: number;
  acceptLanguage?: string;
}

export interface ListChannelCardsChannelCardsGetRequest {
  page?: number;
  perPage?: number;
  categoryId?: number;
  countryId?: number;
  search?: string;
  isActive?: boolean;
  sort?: string;
}

/**
 *
 */
export class ChannelCardsApi extends runtime.BaseAPI {
  /**
   * Get Channel Card
   */
  async getChannelCardChannelCardsIdGetRaw(
    requestParameters: GetChannelCardChannelCardsIdGetRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<ChannelCardResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling getChannelCardChannelCardsIdGet.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      requestParameters.acceptLanguage !== undefined &&
      requestParameters.acceptLanguage !== null
    ) {
      headerParameters['accept-language'] = String(
        requestParameters.acceptLanguage
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('HTTPBearer', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/channel-cards/{id}`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ChannelCardResponseFromJSON(jsonValue)
    );
  }

  /**
   * Get Channel Card
   */
  async getChannelCardChannelCardsIdGet(
    requestParameters: GetChannelCardChannelCardsIdGetRequest,
    initOverrides?: RequestInit
  ): Promise<ChannelCardResponse> {
    const response = await this.getChannelCardChannelCardsIdGetRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * used to list and filter channel_cards for a specific channel
   * List Channel Cards
   */
  async listChannelCardsChannelCardsGetRaw(
    requestParameters: ListChannelCardsChannelCardsGetRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<ChannelCardsPaginatedResponse>> {
    const queryParameters: any = {};

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page;
    }

    if (requestParameters.perPage !== undefined) {
      queryParameters['per_page'] = requestParameters.perPage;
    }

    if (requestParameters.categoryId !== undefined) {
      queryParameters['category_id'] = requestParameters.categoryId;
    }

    if (requestParameters.countryId !== undefined) {
      queryParameters['country_id'] = requestParameters.countryId;
    }

    if (requestParameters.search !== undefined) {
      queryParameters['search'] = requestParameters.search;
    }

    if (requestParameters.isActive !== undefined) {
      queryParameters['is_active'] = requestParameters.isActive;
    }

    if (requestParameters.sort !== undefined) {
      queryParameters['sort'] = requestParameters.sort;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('HTTPBearer', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/channel-cards/`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ChannelCardsPaginatedResponseFromJSON(jsonValue)
    );
  }

  /**
   * used to list and filter channel_cards for a specific channel
   * List Channel Cards
   */
  async listChannelCardsChannelCardsGet(
    requestParameters: ListChannelCardsChannelCardsGetRequest,
    initOverrides?: RequestInit
  ): Promise<ChannelCardsPaginatedResponse> {
    const response = await this.listChannelCardsChannelCardsGetRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }
}
