import Image from 'next/image';
import barcode from 'jsbarcode';
import { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
/* eslint-disable-next-line */
export interface GiftDetailsProps {
  img: string;
  code: string;
  pin?: string | null;
  value: string;
  price: string;
  guideMessage?: string;
  copyMessage: string;
  secretMessage: string;
  sendGiftMessage: string;
  pinMessage: string;
  onShowGuide?: () => void;
  secret?: string;
  showBarcode?: boolean;
  horizontalMode?: boolean;
  showSendAsGift?: boolean;
  onSendAsGift?: () => void;
}

export function GiftDetails({
  img,
  code,
  pin,
  value,
  price,
  guideMessage,
  copyMessage,
  secretMessage,
  pinMessage,
  onShowGuide,
  secret,
  showBarcode,
  horizontalMode,
  showSendAsGift,
  onSendAsGift,
  sendGiftMessage,
}: GiftDetailsProps) {
  const barcodeRef = useRef<SVGSVGElement | null>(null);
  const container = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (barcodeRef.current != null) {
      barcode(barcodeRef.current).init();
    }
  }, [showBarcode, code]);
  const [copied, setCopied] = useState(false);
  return (
    <div
      ref={container}
      className={`py-5 px-4 print:px-0 print:py-1 shadow-xl rounded border-gray-100 border space-b-3${
        horizontalMode ? ' md:grid md:grid-cols-2 md:gap-4 md:space-b-0' : ''
      }`}
    >
      <div className="printHide relative shadow aspect-w-3 aspect-h-2 rounded-md mb-auto">
        <Image src={img} layout="fill" unoptimized objectFit="fill" />
        <div className="text-identity-primary-inverted p-4 flex flex-col">
          <div className="bg-gray-200 bg-opacity-70 absolute inline-end-2 px-2 rounded-md top-2 mt-auto text-start font-medium text-xl">
            {value}
          </div>
          <div className="bg-gray-200 bg-opacity-70 absolute inline-start-2 px-2 rounded-md bottom-2 mt-auto text-start font-medium text-xl">
            {price}
          </div>
        </div>
      </div>
      <div className="space-b-4">
        {showBarcode ? (
          <div className="flex justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 142 142"
              data-value={code}
              ref={barcodeRef}
            ></svg>
          </div>
        ) : null}
        <div className="flex items-baseline space-i-2 printHide">
          <span>{code}</span>
          <button
            className="printHide border py-2 rounded-md text-center w-full relative"
            onClick={() => {
              navigator.clipboard.writeText(code);
              setCopied(true);
            }}
          >
            {copyMessage}
            {copied ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="absolute block-start-1.5 h-6 inline-end-1 text-identity-primary w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-6 9l2 2 4-4"
                />
              </svg>
            ) : null}
          </button>
          {pin != null ? (
            <span>
              {pinMessage} {pin}
            </span>
          ) : null}
        </div>
        {secret != null ? (
          <div>
            {secretMessage} {secret}
          </div>
        ) : null}
        {showSendAsGift && guideMessage == null ? (
          <button
            className="printHide bg-identity-primary px-4 py-2 text-center text-identity-primary-inverted w-full rounded-md"
            onClick={onSendAsGift}
          >
            {sendGiftMessage}
          </button>
        ) : null}
      </div>
      <div>
        {guideMessage != null ? (
          <button
            className="printHide w-full flex justify-between border p-2 rounded-md"
            onClick={onShowGuide}
          >
            <span>{guideMessage}</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        ) : null}
      </div>
      <div>
        {showSendAsGift && guideMessage != null ? (
          <button
            className="printHide bg-identity-primary px-4 py-2 text-center text-identity-primary-inverted w-full rounded-md"
            onClick={onSendAsGift}
          >
            {sendGiftMessage}
          </button>
        ) : null}
      </div>
    </div>
  );
}

export default GiftDetails;
