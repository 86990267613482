/* tslint:disable */
/* eslint-disable */
/**
 * channels-com
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface Card
 */
export interface Card {
  /**
   *
   * @type {number}
   * @memberof Card
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof Card
   */
  titleAr: string;
  /**
   *
   * @type {string}
   * @memberof Card
   */
  titleEn: string;
  /**
   *
   * @type {string}
   * @memberof Card
   */
  usageAr: string;
  /**
   *
   * @type {string}
   * @memberof Card
   */
  usageEn: string;
  /**
   *
   * @type {string}
   * @memberof Card
   */
  descriptionAr: string;
  /**
   *
   * @type {string}
   * @memberof Card
   */
  descriptionEn: string;
  /**
   *
   * @type {Array<number>}
   * @memberof Card
   */
  marketIds?: Array<number>;
  /**
   *
   * @type {number}
   * @memberof Card
   */
  categoryId?: number;
  /**
   *
   * @type {boolean}
   * @memberof Card
   */
  isActive: boolean;
  /**
   *
   * @type {boolean}
   * @memberof Card
   */
  isInventoryActive: boolean;
  /**
   *
   * @type {Date}
   * @memberof Card
   */
  createdAt: Date;
  /**
   *
   * @type {Date}
   * @memberof Card
   */
  updatedAt: Date;
}

export function CardFromJSON(json: any): Card {
  return CardFromJSONTyped(json, false);
}

export function CardFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Card {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    titleAr: json['title_ar'],
    titleEn: json['title_en'],
    usageAr: json['usage_ar'],
    usageEn: json['usage_en'],
    descriptionAr: json['description_ar'],
    descriptionEn: json['description_en'],
    marketIds: !exists(json, 'market_ids') ? undefined : json['market_ids'],
    categoryId: !exists(json, 'category_id') ? undefined : json['category_id'],
    isActive: json['is_active'],
    isInventoryActive: json['is_inventory_active'],
    createdAt: new Date(json['created_at']),
    updatedAt: new Date(json['updated_at']),
  };
}

export function CardToJSON(value?: Card | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    title_ar: value.titleAr,
    title_en: value.titleEn,
    usage_ar: value.usageAr,
    usage_en: value.usageEn,
    description_ar: value.descriptionAr,
    description_en: value.descriptionEn,
    market_ids: value.marketIds,
    category_id: value.categoryId,
    is_active: value.isActive,
    is_inventory_active: value.isInventoryActive,
    created_at: value.createdAt.toISOString(),
    updated_at: value.updatedAt.toISOString(),
  };
}
