/* tslint:disable */
/* eslint-disable */
/**
 * channels-com
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface CartItem
 */
export interface CartItem {
  /**
   *
   * @type {number}
   * @memberof CartItem
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof CartItem
   */
  valueAr: string;
  /**
   *
   * @type {string}
   * @memberof CartItem
   */
  valueEn: string;
  /**
   *
   * @type {string}
   * @memberof CartItem
   */
  image: string;
  /**
   *
   * @type {number}
   * @memberof CartItem
   */
  quantity: number;
  /**
   *
   * @type {number}
   * @memberof CartItem
   */
  price: number;
  /**
   *
   * @type {number}
   * @memberof CartItem
   */
  quantityTotal: number;
  /**
   *
   * @type {boolean}
   * @memberof CartItem
   */
  barcode: boolean;
  /**
   *
   * @type {number}
   * @memberof CartItem
   */
  inventoryQuantity: number;
  /**
   *
   * @type {number}
   * @memberof CartItem
   */
  cardId?: number;
}

export function CartItemFromJSON(json: any): CartItem {
  return CartItemFromJSONTyped(json, false);
}

export function CartItemFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): CartItem {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    valueAr: json['value_ar'],
    valueEn: json['value_en'],
    image: json['image'],
    quantity: json['quantity'],
    price: json['price'],
    quantityTotal: json['quantity_total'],
    barcode: json['barcode'],
    inventoryQuantity: json['inventory_quantity'],
    cardId: json['card_id'],
  };
}

export function CartItemToJSON(value?: CartItem | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    value_ar: value.valueAr,
    value_en: value.valueEn,
    image: value.image,
    quantity: value.quantity,
    price: value.price,
    quantity_total: value.quantityTotal,
    barcode: value.barcode,
    inventory_quantity: value.inventoryQuantity,
    card_id: value.cardId,
  };
}
