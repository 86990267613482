/* tslint:disable */
/* eslint-disable */
/**
 * channels-com
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import { User, UserFromJSON, UserFromJSONTyped, UserToJSON } from './';

/**
 *
 * @export
 * @interface LoginResponse
 */
export interface LoginResponse {
  /**
   *
   * @type {string}
   * @memberof LoginResponse
   */
  accessToken: string;
  /**
   *
   * @type {string}
   * @memberof LoginResponse
   */
  tokenType: string;
  /**
   *
   * @type {User}
   * @memberof LoginResponse
   */
  user: User;
}

export function LoginResponseFromJSON(json: any): LoginResponse {
  return LoginResponseFromJSONTyped(json, false);
}

export function LoginResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): LoginResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    accessToken: json['access_token'],
    tokenType: json['token_type'],
    user: UserFromJSON(json['user']),
  };
}

export function LoginResponseToJSON(value?: LoginResponse | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    access_token: value.accessToken,
    token_type: value.tokenType,
    user: UserToJSON(value.user),
  };
}
