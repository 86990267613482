/* tslint:disable */
/* eslint-disable */
/**
 * channels-com
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  RedemptionCard,
  RedemptionCardFromJSON,
  RedemptionCardFromJSONTyped,
  RedemptionCardToJSON,
} from './';

/**
 *
 * @export
 * @interface SingleOrder
 */
export interface SingleOrder {
  /**
   *
   * @type {number}
   * @memberof SingleOrder
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof SingleOrder
   */
  vat: number;
  /**
   *
   * @type {number}
   * @memberof SingleOrder
   */
  subtotal: number;
  /**
   *
   * @type {number}
   * @memberof SingleOrder
   */
  total: number;
  /**
   *
   * @type {Date}
   * @memberof SingleOrder
   */
  createdAt: Date;
  /**
   *
   * @type {Array<RedemptionCard>}
   * @memberof SingleOrder
   */
  cards: Array<RedemptionCard>;
}

export function SingleOrderFromJSON(json: any): SingleOrder {
  return SingleOrderFromJSONTyped(json, false);
}

export function SingleOrderFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): SingleOrder {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    vat: json['vat'],
    subtotal: json['subtotal'],
    total: json['total'],
    createdAt: new Date(json['created_at']),
    cards: (json['cards'] as Array<any>).map(RedemptionCardFromJSON),
  };
}

export function SingleOrderToJSON(value?: SingleOrder | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    total: value.total,
    created_at: value.createdAt.toISOString(),
    cards: (value.cards as Array<any>).map(RedemptionCardToJSON),
  };
}
