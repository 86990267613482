import { useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useMutation, useQuery } from 'react-query';
import { useTokenStore } from '@stores';
import { CartStore } from './cart-store';
import { cartsApi } from '../utils/api';
import { AddCartItemCartsItemPostRequest, CartItem } from '@httpclient';
export const useSyncCart = (
  setCart: React.Dispatch<React.SetStateAction<CartItem[] | undefined>>
) => {
  const { mutate: senditemsToCart } = useMutation(
    (args: AddCartItemCartsItemPostRequest) =>
      cartsApi.addCartItemCartsItemPost(args)
  );
  const loggedIn = useTokenStore((store) => store.loggedIn());
  const { locale } = useIntl();

  const { data, isLoading, refetch } = useQuery(
    [
      'getChannelCart',
      {
        acceptLanguage: locale,
      },
    ],
    async () =>
      await cartsApi.getCartCartsGet({
        acceptLanguage: locale,
      }),
    {
      enabled: loggedIn,
      notifyOnChangeProps: ['data', 'isLoading'],
    }
  );
  const { items: cartItems, clear, removeItem } = CartStore();

  const isSyncing = useRef(false);
  useEffect(() => {
    if (isLoading || isSyncing.current) return;
    if (!loggedIn && cartItems != null && cartItems.length < 1) {
      setCart(undefined);
      return;
    }
    let unmounted = false;
    const hasLocalItems = cartItems != null && cartItems.length > 0;
    const hasRemoteItems = data != null && (data.cart.total ?? 0) > 0;

    // the cart has been emptied
    if (!hasLocalItems && data != null && (data.cart.total ?? 0) < 1) {
      setCart(undefined);
    } else if (hasRemoteItems && !hasLocalItems) {
      // no local items, only remote
      setCart(
        data?.cart
          ? data?.cart.items.map((item) => ({
              id: item.id,
              image: item.image,
              price: item.price,
              quantity: item.quantity,
              valueAr: item.valueAr,
              valueEn: item.valueEn,
              quantityTotal: item.quantity ** item.price,
              barcode: item.barcode,
              inventoryQuantity: item.inventoryQuantity,
              cardId: item.cardId,
            }))
          : []
      );
    } else if (hasLocalItems) {
      if (!loggedIn) {
        setCart((curCart) => {
          if (!curCart) return cartItems;
          if (curCart === cartItems) return curCart;
          if (curCart.length !== cartItems?.length) return cartItems;
          if (
            curCart.some((cur, idx) => {
              const newItem = cartItems[idx];
              if (
                newItem.id !== cur.id ||
                newItem.price !== cur.price ||
                newItem.quantity !== cur.quantity
              )
                return true;
            })
          ) {
            return cartItems;
          }
          return curCart;
        });
        return;
      }
      isSyncing.current = true;
      const remoteDenom = data?.cart
        ? data?.cart.items.map((item) => item.id)
        : [];
      const toAdd: AddCartItemCartsItemPostRequest = {
        bodyAddCartItemCartsItemPost: {
          quantity: 0,
          denominationId: 0,
        },
        acceptLanguage: locale,
      };
      cartItems?.forEach((item) => {
        if (!remoteDenom.includes(item.id)) {
          toAdd.bodyAddCartItemCartsItemPost = {
            quantity: item.quantity,
            denominationId: item.id,
          };
          toAdd.acceptLanguage = locale;
        }
      });
      if (toAdd?.bodyAddCartItemCartsItemPost?.quantity < 1) {
        clear();
        return;
      }
      senditemsToCart(
        {
          bodyAddCartItemCartsItemPost: {
            quantity: toAdd.bodyAddCartItemCartsItemPost.quantity,
            denominationId: toAdd.bodyAddCartItemCartsItemPost.denominationId,
          },
          acceptLanguage: locale,
        },
        {
          onSettled() {
            isSyncing.current = false;
          },
        }
      );
    }
    return () => {
      unmounted = true;
    };
  }, [
    cartItems,
    data,
    isLoading,
    senditemsToCart,
    refetch,
    locale,
    clear,
    setCart,
    loggedIn,
  ]);
};
