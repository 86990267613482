/* tslint:disable */
/* eslint-disable */
/**
 * channels-com
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import { ApiKey, ApiKeyFromJSON, ApiKeyFromJSONTyped, ApiKeyToJSON } from './';

/**
 *
 * @export
 * @interface RevokeApiKeyResponse
 */
export interface RevokeApiKeyResponse {
  /**
   *
   * @type {string}
   * @memberof RevokeApiKeyResponse
   */
  message: string;
  /**
   *
   * @type {ApiKey}
   * @memberof RevokeApiKeyResponse
   */
  apiKey: ApiKey;
}

export function RevokeApiKeyResponseFromJSON(json: any): RevokeApiKeyResponse {
  return RevokeApiKeyResponseFromJSONTyped(json, false);
}

export function RevokeApiKeyResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): RevokeApiKeyResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    message: json['message'],
    apiKey: ApiKeyFromJSON(json['api_key']),
  };
}

export function RevokeApiKeyResponseToJSON(
  value?: RevokeApiKeyResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    message: value.message,
    api_key: ApiKeyToJSON(value.apiKey),
  };
}
