/* tslint:disable */
/* eslint-disable */
/**
 * channels-com
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  ChannelCardWithDenoms,
  ChannelCardWithDenomsFromJSON,
  ChannelCardWithDenomsFromJSONTyped,
  ChannelCardWithDenomsToJSON,
} from './';

/**
 *
 * @export
 * @interface ChannelCardResponse
 */
export interface ChannelCardResponse {
  /**
   *
   * @type {string}
   * @memberof ChannelCardResponse
   */
  message: string;
  /**
   *
   * @type {ChannelCardWithDenoms}
   * @memberof ChannelCardResponse
   */
  channelCard: ChannelCardWithDenoms;
}

export function ChannelCardResponseFromJSON(json: any): ChannelCardResponse {
  return ChannelCardResponseFromJSONTyped(json, false);
}

export function ChannelCardResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ChannelCardResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    message: json['message'],
    channelCard: ChannelCardWithDenomsFromJSON(json['channel_card']),
  };
}

export function ChannelCardResponseToJSON(
  value?: ChannelCardResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    message: value.message,
    channel_card: ChannelCardWithDenomsToJSON(value.channelCard),
  };
}
