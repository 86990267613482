import React from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useTokenStore } from '@stores';
import { InputWrapper, useAlerts } from '@resal-frontend/ui/index';
import { z } from 'zod';
import messages from './messages';
import { usersApi } from '../../utils/api';
import { useMutation } from 'react-query';
import { LoginAccessTokenUsersLoginPostRequest } from '@httpclient';

export interface LoginProps {
  register: () => void;
  onComplete: () => void;
  forgot: () => void;
}

const schema = (formatMessage: IntlShape['formatMessage']) =>
  z
    .object({
      password: z.string().nonempty(formatMessage(messages.required)),
    })
    .extend({
      email: z
        .string()
        .email(formatMessage(messages.invalidEmail))
        .nonempty(formatMessage(messages.required)),
    });

export function Login({
  register: registerFn,
  onComplete,
  forgot,
}: LoginProps) {
  const { formatMessage, locale } = useIntl();
  const { setTokens, setUser } = useTokenStore();
  const { register, handleSubmit, errors, setError } = useForm({
    resolver: (values: any) => zodResolver(schema(formatMessage))(values),
    context: {
      locale,
    },
  });
  const { addAlert } = useAlerts();
  const { mutate, isLoading } = useMutation(
    (args: LoginAccessTokenUsersLoginPostRequest) =>
      usersApi.loginAccessTokenUsersLoginPost(args),
    {
      onSuccess: (data) => {
        setTokens({ accessToken: data.accessToken });
        setUser({
          channel: data.user.channel,
          email: data.user.email,
          name: data.user.name,
          id: data.user.id,
          phone: data.user.phone,
        });
        onComplete();
      },
      onError: async (error: any) => {
        error = await error.json();
        addAlert({ message: error.detail });
      },
    }
  );
  return (
    <>
      <h1 className="mt-8 header-text">{formatMessage(messages.login)}</h1>
      <form
        className="space-y-4 mt-3"
        onSubmit={handleSubmit((d) =>
          mutate({
            userLogin: { email: d.email, password: d.password },
            acceptLanguage: locale,
          })
        )}
      >
        <div>
          <InputWrapper
            label={formatMessage(messages.email)}
            name="email"
            error={errors?.email?.message}
          >
            <input
              dir="ltr"
              type="email"
              name="email"
              id="email"
              className="focus:ring-identity-primary focus:border-identity-primary block w-full sm:text-sm border-gray-300 rounded-md text-left mt-1"
              ref={register}
            />
          </InputWrapper>
        </div>
        <div>
          <InputWrapper
            name="password"
            label={formatMessage(messages.password)}
            error={errors?.password?.message}
          >
            <input
              dir="ltr"
              type="password"
              name="password"
              id="password"
              className="focus:ring-identity-primary focus:border-identity-primary block w-full sm:text-sm border-gray-300 rounded-md text-left mt-1"
              ref={register}
            />
          </InputWrapper>
        </div>
        <div>
          <button
            className="text-identity-primary"
            onClick={forgot}
            type="button"
          >
            {formatMessage(messages.forgotPassword)}
          </button>
        </div>
        <div>
          <button
            disabled={isLoading}
            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-identity-primary-inverted bg-identity-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-identity-primary disabled:opacity-60"
          >
            {formatMessage(messages.login)}
          </button>
        </div>
        <div>
          {formatMessage(messages.dontHaveAcct)}
          <button
            type="button"
            className="text-identity-primary mis-1"
            onClick={registerFn}
          >
            {formatMessage(messages.register)}
          </button>
        </div>
      </form>
    </>
  );
}

export default Login;
