import React from 'react';

const IconContainer = (props: { children: React.ReactNode }) => {
  return <span {...props} />;
};

const DefaultIcon = (props: { classes: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={`h-6 w-6 ${props.classes}`}
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1}
      d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
    />
  </svg>
);

interface classesType {
  [key: string]: string;
}

const classes: classesType = {
  root: 'fill-current',
  fillColor: 'text-yellow-400',
  hoverColor: 'text-yellow-400',
  emptyColor: 'text-gray-300',
};

const getClasses = (classesState: { [key: string]: boolean }) => {
  let appliedClasses = '';
  if (classesState.filledColor) appliedClasses = classes['fillColor'];
  else if (classesState.hoverColor) appliedClasses = classes['hoverColor'];
  else if (classesState.emptyColor) appliedClasses = classes['emptyColor'];
  return `${classes.root} ${appliedClasses}`;
};

export interface RatingProps {
  max: number;
  value: number;
  onBlur?: () => void;
  onFocus?: () => void;
  onChange: (newValue: number) => void;
  name?: string;
  renderChild?: (args: {
    index: number;
    itemValue: number;
    isHover: boolean;
    isSelected: boolean;
    isFocus: boolean;
    hover: number;
    value: number;
  }) => React.ReactNode;
}

export function Rating({
  max,
  onBlur,
  onFocus,
  onChange,
  value,
  name,
  renderChild,
}: RatingProps) {
  const [hover, setHover] = React.useState<number>(-1);
  const [focus, setFocus] = React.useState<number>(-1);
  const rootRef = React.useRef<HTMLDivElement>(null);
  const focusRef = React.useRef(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(event.target.value);
    onChange(newValue);
  };

  const handleMouseEnter = (
    event: React.MouseEvent<HTMLLabelElement, MouseEvent>
  ) => {
    console.log(
      'hover',
      (event.currentTarget.previousSibling as HTMLInputElement).value
    );
    setHover(
      parseInt(
        (event.currentTarget.previousSibling as HTMLInputElement).value,
        10
      )
    );
  };

  const handleMouseLeave = () => {
    // reset the state hover to inital state
    setHover(-1);
  };

  const handleFocus = (value: number) => {
    if (onFocus != null && !focusRef.current) {
      focusRef.current = true;
      onFocus();
    }
    setFocus(value);
  };

  const clearStates = (
    event: React.MouseEvent<HTMLInputElement, MouseEvent>
  ) => {
    // work around for => onClick firing when radio button is selcted with arrow keys
    //https://github.com/facebook/react/issues/7407
    if (event.type === 'click' && event.clientX !== 0 && event.clientY !== 0) {
      // This is a real click. Do something here
      setFocus(-1);
      setHover(-1);
    }
  };

  const handleBlur = () => {
    if (onBlur != null && focusRef.current) {
      focusRef.current = false;
      onBlur();
    }
    setFocus(-1);
  };

  return (
    <div className="flex w-full" ref={rootRef} onMouseLeave={handleMouseLeave}>
      {[...Array(max)].map((_, index) => {
        const itemValue = index + 1;
        const isHover = hover === itemValue;
        const isFocus = focus === itemValue;
        const isSelected = itemValue === value;

        const classes = getClasses({
          filledColor: hover === -1 && itemValue <= value,
          hoverColor: itemValue <= hover,
          emptyColor: !(itemValue <= hover),
        });

        return (
          <span
            className={`${
              focus === itemValue ? 'border-2 border-blue-400' : ''
            }`}
            key={itemValue}
          >
            <input
              id={`star-${name}-${itemValue}`}
              onFocus={() => handleFocus(itemValue)}
              onBlur={handleBlur}
              onClick={clearStates}
              onChange={handleChange}
              type="radio"
              name="rating"
              className="sr-only"
              value={itemValue}
              checked={itemValue === value}
            />
            <label
              className="cursor-pointer"
              htmlFor={`star-${name}-${itemValue}`}
              onMouseEnter={handleMouseEnter}
            >
              {
                <IconContainer>
                  {renderChild ? (
                    renderChild({
                      index,
                      itemValue,
                      isHover,
                      hover,
                      isSelected,
                      isFocus,
                      value,
                    })
                  ) : (
                    <DefaultIcon classes={classes} />
                  )}
                </IconContainer>
              }
              <span className="sr-only">{`${itemValue} star${
                itemValue === 1 ? '' : 's'
              }`}</span>
            </label>
          </span>
        );
      })}
    </div>
  );
}

export default Rating;
