import Image from 'next/image';
import React, { useCallback, useEffect, useState } from 'react';
import { UIStateStore, useTokenStore } from '@stores';
import { Modal } from '../modal';
import { ForgotPassword } from './forgotPassword';
import { Login } from './login';
// import { Otp } from './otp';
import { Register } from './register';
import { useChannel } from '../../utils/channel-context';

export interface LoginRegisterProps {}

export const LoginRegister = ({}: LoginRegisterProps) => {
  const { mode: currentMode, setUIMode } = UIStateStore();
  const [mobile, setMobile] = useState('');
  const [mode, setMode] = useState(currentMode);
  const { user, loggedIn } = useTokenStore();

  useEffect(() => {
    // if the modal is closing
    // preserve the state so that the modal
    // does not collapse while fading out
    if (currentMode === 'idle') return;
    setMode(currentMode);
  }, [currentMode]);
  const onClose = useCallback(() => {
    if (loggedIn()) {
      setUIMode('idle');
    }
  }, [user]);
  const afterLeave = useCallback(() => {
    if (currentMode != mode) {
      setMode(currentMode);
    }
  }, [mode, currentMode]);
  const channel = useChannel();
  return (
    <Modal
      open={
        currentMode === 'forgot' ||
        currentMode === 'login' ||
        currentMode === 'register'
        // currentMode === 'otp'
      }
      onClose={onClose}
      afterLeave={afterLeave}
    >
      <div
        className="p-6 sm:px-16 sm:pt-16 sm:pb-12 flex flex-col relative text-start w-screen max-w-full sm:w-123 overflow-y-auto modal-bg"
        style={{ maxHeight: 'calc(100vh - 1rem)' }}
      >
        <div className="mx-auto">
          <Image
            src={channel.logo.url}
            alt={channel.name}
            width={250}
            height={(channel.logo.height * 400) / channel.logo.width}
            layout="intrinsic"
            unoptimized={channel.logo.extension === 'svg'}
          />
        </div>
        {mode === 'login' ? (
          <Login
            register={() => {
              setUIMode('register');
            }}
            onComplete={onClose}
            forgot={() => {
              setUIMode('forgot');
            }}
          />
        ) : mode === 'register' ? (
          <Register
            login={() => {
              setUIMode('login');
            }}
            verify={(mobile) => {
              setMobile(mobile);
              // setUIMode('otp');
            }}
          />
        ) : // ) : mode === 'otp' ? (
        //   <Otp mobile={mobile} onCompleteVerification={onClose} />
        mode === 'forgot' ? (
          <ForgotPassword
            login={() => {
              setUIMode('login');
            }}
          />
        ) : null}
      </div>
    </Modal>
  );
};
