/* tslint:disable */
/* eslint-disable */
/**
 * channels-com
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface MinBalance
 */
export interface MinBalance {
  /**
   *
   * @type {number}
   * @memberof MinBalance
   */
  lowBalance: number;
  /**
   *
   * @type {number}
   * @memberof MinBalance
   */
  channelId: number;
  /**
   *
   * @type {Array<string>}
   * @memberof MinBalance
   */
  emails: Array<string>;
}

export function MinBalanceFromJSON(json: any): MinBalance {
  return MinBalanceFromJSONTyped(json, false);
}

export function MinBalanceFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): MinBalance {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    lowBalance: json['low_balance'],
    channelId: json['channel_id'],
    emails: json['emails'],
  };
}

export function MinBalanceToJSON(value?: MinBalance | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    low_balance: value.lowBalance,
    channel_id: value.channelId,
    emails: value.emails,
  };
}
