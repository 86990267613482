/* tslint:disable */
/* eslint-disable */
/**
 * channels-com
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  Channel,
  ChannelFromJSON,
  ChannelFromJSONTyped,
  ChannelToJSON,
} from './';

/**
 *
 * @export
 * @interface User
 */
export interface User {
  /**
   *
   * @type {string}
   * @memberof User
   */
  email: string;
  /**
   *
   * @type {number}
   * @memberof User
   */
  balanceMsReference?: number;
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  isActive?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  isVerified?: boolean;
  /**
   *
   * @type {string}
   * @memberof User
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  phone: string;
  /**
   *
   * @type {number}
   * @memberof User
   */
  id: number;
  /**
   *
   * @type {Channel}
   * @memberof User
   */
  channel: Channel;
}

export function UserFromJSON(json: any): User {
  return UserFromJSONTyped(json, false);
}

export function UserFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): User {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    email: json['email'],
    balanceMsReference: !exists(json, 'balance_ms_reference')
      ? undefined
      : json['balance_ms_reference'],
    isActive: !exists(json, 'is_active') ? undefined : json['is_active'],
    isVerified: !exists(json, 'is_verified') ? undefined : json['is_verified'],
    name: json['name'],
    phone: json['phone'],
    id: json['id'],
    channel: ChannelFromJSON(json['channel']),
  };
}

export function UserToJSON(value?: User | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    email: value.email,
    balance_ms_reference: value.balanceMsReference,
    is_active: value.isActive,
    is_verified: value.isVerified,
    name: value.name,
    phone: value.phone,
    id: value.id,
    channel: ChannelToJSON(value.channel),
  };
}
