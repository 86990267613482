/* tslint:disable */
/* eslint-disable */
/**
 * channels-com
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import { Card, CardFromJSON, CardFromJSONTyped, CardToJSON } from './';

/**
 *
 * @export
 * @interface ChannelCard
 */
export interface ChannelCard {
  /**
   *
   * @type {number}
   * @memberof ChannelCard
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof ChannelCard
   */
  channelId: number;
  /**
   *
   * @type {Card}
   * @memberof ChannelCard
   */
  card: Card;
  /**
   *
   * @type {boolean}
   * @memberof ChannelCard
   */
  isActive: boolean;
  /**
   *
   * @type {Date}
   * @memberof ChannelCard
   */
  createdAt: Date;
  /**
   *
   * @type {Date}
   * @memberof ChannelCard
   */
  updatedAt: Date;
  /**
   *
   * @type {number}
   * @memberof ChannelCard
   */
  listingOrder?: number;
  /**
   *
   * @type {number}
   * @memberof ChannelCard
   */
  beginPrice: number;
  /**
   *
   * @type {string}
   * @memberof ChannelCard
   */
  image: string;
}

export function ChannelCardFromJSON(json: any): ChannelCard {
  return ChannelCardFromJSONTyped(json, false);
}

export function ChannelCardFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ChannelCard {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    channelId: json['channel_id'],
    card: CardFromJSON(json['card']),
    isActive: json['is_active'],
    createdAt: new Date(json['created_at']),
    updatedAt: new Date(json['updated_at']),
    listingOrder: !exists(json, 'listing_order')
      ? undefined
      : json['listing_order'],
    beginPrice: json['begin_price'],
    image: json['image'],
  };
}

export function ChannelCardToJSON(value?: ChannelCard | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    channel_id: value.channelId,
    card: CardToJSON(value.card),
    is_active: value.isActive,
    created_at: value.createdAt.toISOString(),
    updated_at: value.updatedAt.toISOString(),
    listing_order: value.listingOrder,
    begin_price: value.beginPrice,
    image: value.image,
  };
}
