import { defineMessages } from 'react-intl';

export default defineMessages({
  personalInfo: {
    id: 'nav.personalInfo',
    defaultMessage: 'My Personal Information',
  },
  orders: {
    id: 'nav.orders',
    defaultMessage: 'My Orders',
  },
  addresses: {
    id: 'nav.addresses',
    defaultMessage: 'Addresses',
  },
  resalCards: {
    id: 'nav.resalCards',
    defaultMessage: 'Resal Cards',
  },
  balance: {
    id: 'nav.balance',
    defaultMessage: 'Balance',
  },
  calendar: {
    id: 'nav.calendar',
    defaultMessage: 'Calendar',
  },
  changePassword: {
    id: 'nav.changePassword',
    defaultMessage: 'Change Password',
  },
  apiKey: {
    id: 'nav.apiKey',
    defaultMessage: 'Api Key',
  },
  generate: {
    id: 'profile.generate',
    defaultMessage: 'Update keys',
  },
  secret: {
    id: 'profile.secret',
    defaultMessage: 'Secret Key',
  },
  secretWarning: {
    id: 'profile.secretWarning',
    defaultMessage:
      'The Secret Key will be removed after logging out the page, please keep it in a safe place and don’t share it.',
  },
  warningTitle: {
    id: 'profile.warningTitle',
    defaultMessage: 'Warning',
  },
  warningMsg: {
    id: 'profile.warningMsg',
    defaultMessage: 'This process cannot be undone, are you sure?',
  },
  warningConfirm: {
    id: 'profile.warningConfirm',
    defaultMessage: 'Yes, Confirm',
  },
  warningCancel: {
    id: 'profile.warningCancel',
    defaultMessage: 'Cancel',
  },
  keyNotAllowed: {
    id: 'profile.keyNotAllowed',
    defaultMessage: 'Sorry, you don’t have the permission to access this',
  },
  chargeAccount: {
    id: 'profile.chargeAccount',
    defaultMessage: 'Charge Account',
  },
  lowbalanceAlert: {
    id: 'profile.lowbalanceAlert',
    defaultMessage: 'Managing Balance Alerts',
  },
  lowbalanceAlertDesc: {
    id: 'profile.lowbalanceAlertDesc',
    defaultMessage:
      'A notification will be sent when the balance finished or when the minimum limit is reached.',
  },
  addAmount: {
    id: 'profile.addAmount',
    defaultMessage: 'Add amount to charge your account',
  },
  lowBalanceInput: {
    id: 'profile.lowBalanceInput',
    defaultMessage: 'Notify me when the balance reached this limit:',
  },
  maxAmount: {
    id: 'profile.maxAmount',
    defaultMessage: 'Maximum monthly wallet balance is 1500 S.R.',
  },
  chargeSafely: {
    id: 'profile.chargeSafely',
    defaultMessage: 'You can charge your account safely using credit cards',
  },
  buySafely: {
    id: 'profile.buySafely',
    defaultMessage: 'You can buy safely using credit cards',
  },
  cardNumber: {
    id: 'profile.cardNumber',
    defaultMessage: 'Card number',
  },
  lowBalanceEmails: {
    id: 'profile.lowBalanceEmails',
    defaultMessage: ' Send a notification for the following emails:',
  },
  lowBalanceEmailsError: {
    id: 'profile.lowBalanceEmailsError',
    defaultMessage: 'Enter a valid emails',
  },
  cardHolderName: {
    id: 'profile.cardHolderName',
    defaultMessage: 'Card holder name',
  },
  cardExpireDate: {
    id: 'profile.cardExpireDate',
    defaultMessage: 'Card expiry date',
  },
  cardVericationCode: {
    id: 'profile.cardVericationCode',
    defaultMessage: 'Card CVV code',
  },
  cardChargeConfirm: {
    id: 'profile.cardChargeConfirm',
    defaultMessage: 'Confirm payment to charge balance',
  },
  balanceChargeDetails: {
    id: 'profile.balanceChargeDetails',
    defaultMessage: 'Balance charge details',
  },
  chargeDate: {
    id: 'profile.chargeDate',
    defaultMessage: 'Date',
  },
  chargeAmount: {
    id: 'profile.chargeAmount',
    defaultMessage: 'Amount',
  },
  chargeNumber: {
    id: 'profile.chargeNumber',
    defaultMessage: 'Operation number',
  },
  chargeStatus: {
    id: 'profile.chargeStatus',
    defaultMessage: 'Status',
  },
  chargeSucessful: {
    id: 'profile.chargeSucessful',
    defaultMessage: 'Charge successful',
  },
  chargeFailed: {
    id: 'profile.chargeFailed',
    defaultMessage: 'Charge failed',
  },
  oldPassword: {
    id: 'profile.oldPassword',
    defaultMessage: 'Old password',
  },
  newPassword: {
    id: 'profile.newPassword',
    defaultMessage: 'New password',
  },
  confirmPassword: {
    id: 'profile.confirmPassword',
    defaultMessage: 'Confirm password',
  },
  savePassword: {
    id: 'profile.savePassword',
    defaultMessage: 'Save',
  },
  saveLowBalance: {
    id: 'profile.saveLowBalance',
    defaultMessage: 'Save Updates',
  },
  orderNumber: {
    id: 'profile.orderNumber',
    defaultMessage: 'Order number',
  },
  orderDate: {
    id: 'profile.orderDate',
    defaultMessage: 'Order date',
  },
  orderSubTotal: {
    id: 'profile.orderSubTotal',
    defaultMessage: 'Sub total',
  },
  orderVat: {
    id: 'profile.orderVat',
    defaultMessage: 'VAT',
  },
  orderTotal: {
    id: 'profile.orderTotal',
    defaultMessage: 'Total',
  },
  downloadReport: {
    id: 'profile.downloadReport',
    defaultMessage: 'Download Order',
  },
  details: {
    id: 'profile.details',
    defaultMessage: 'Details',
  },
  profileFullName: {
    id: 'profile.fullName',
    defaultMessage: 'Full name',
  },
  channelName: {
    id: 'profile.channelName',
    defaultMessage: 'Channel name',
  },
  profileGender: {
    id: 'profile.gender',
    defaultMessage: 'Gender',
  },
  profileGenderMale: {
    id: 'profile.genderMale',
    defaultMessage: 'Male',
  },
  profileGenderFemale: {
    id: 'profile.genderFemale',
    defaultMessage: 'Female',
  },
  profileCountry: {
    id: 'profile.country',
    defaultMessage: 'Country',
  },
  mobileNotActivated: {
    id: 'profile.mobileNotActive',
    defaultMessage: 'Mobile is not activated',
  },
  mobileActivate: {
    id: 'profile.mobileActivate',
    defaultMessage: 'Activate now',
  },
  country: {
    id: 'profile.country',
    defaultMessage: 'Country',
  },
  city: {
    id: 'profile.city',
    defaultMessage: 'City',
  },
  save: {
    id: 'profile.save',
    defaultMessage: 'Save',
  },
  chargeBalanceCredit: {
    id: 'profile.chargeBalanceCredit',
    defaultMessage: 'Add Specific amount',
  },
  chargeBalanceGiftcard: {
    id: 'profile.chargeBalanceGiftcard',
    defaultMessage: 'Transfer amount',
  },
  activateGiftCard: {
    id: 'profile.activateGiftCard',
    defaultMessage: 'Activate Resal card',
  },
  haveGiftCard: {
    id: 'profile.haveGiftCard',
    defaultMessage: 'Got a Resal gift card?',
  },
  activateGiftCardToUse: {
    id: 'profile.activateGiftCardToUse',
    defaultMessage:
      'Activate Resal gift card to charge your balance and by any e-gift card',
  },
  addGiftAmount: {
    id: 'profile.addGiftAmount',
    defaultMessage: 'Add Resal card code to charge your account',
  },
  cardCode: {
    id: 'profile.cardCode',
    defaultMessage: 'Code',
  },
  giftCardBalance: {
    id: 'profile.cardBalance',
    defaultMessage: 'Card Balance',
  },
  giftCardExpire: {
    id: 'profile.giftCardExpire',
    defaultMessage: 'Expiry Date',
  },
  dontHaveGiftCard: {
    id: 'profile.dontHaveGiftCard',
    defaultMessage: "Don't have a Resal gift card?",
  },
  buyGiftCardMessage: {
    id: 'profile.buyGiftCardMessage',
    defaultMessage:
      'Buy a Resal gift card and give it to a loved one or charge your balance to by any eGift Card',
  },
  buyGiftCard: {
    id: 'profile.buyGiftCard',
    defaultMessage: 'Buy a Resal gift card',
  },
  activateCode: {
    id: 'profile.activateCode',
    defaultMessage: 'Activate Code',
  },
  ccInvlaidMonth: {
    id: 'profile.ccInvlaidMonth',
    defaultMessage: 'Invalid month',
  },
  ccInvlaidYear: {
    id: 'profile.ccInvlaidYear',
    defaultMessage: 'Invalid year',
  },
  ccInvlaidNumber: {
    id: 'profile.ccInvlaidNumber',
    defaultMessage: 'Invalid number',
  },
  ccInvlaidCardHolder: {
    id: 'profile.ccInvlaidCardHolder',
    defaultMessage: 'Invalid Card Holder name',
  },
  ccInvlaidCardCVV: {
    id: 'profile.ccInvlaidCardCVV',
    defaultMessage: 'Invalid verification code',
  },
  profileTitle: {
    id: 'profile.title',
    defaultMessage: 'Profile',
  },
  passwordChangeSuccessful: {
    id: 'profile.passwordOk',
    defaultMessage: 'Password changed successfully',
  },
  invalidAmount: {
    id: 'profile.invalidAmount',
    defaultMessage: 'Invalid amount',
  },
  smallAmount: {
    id: 'profile.smallAmount',
    defaultMessage: 'Mininum amount is {amt}',
  },
  reviewOrder: {
    id: 'profile.reviewOrder',
    defaultMessage: 'Your review will bring joy to our hearts',
  },
  reviewDone: {
    id: 'profile.reviewDone',
    defaultMessage: 'Thank you for your review',
  },
  reviewBtn: {
    id: 'profile.reviewBtn',
    defaultMessage: 'Review',
  },
  orderType: {
    id: 'profile.orderType',
    defaultMessage: 'Order type',
  },
});
