/* tslint:disable */
/* eslint-disable */
/**
 * channels-com
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface Order
 */
export interface Order {
  /**
   *
   * @type {number}
   * @memberof Order
   */
  id: number;
  /**
   *
   * @type {Date}
   * @memberof Order
   */
  createdAt: Date;
  /**
   *
   * @type {number}
   * @memberof Order
   */
  total: number;
  /**
   *
   * @type {string}
   * @memberof Order
   */
  source: string;
}

export function OrderFromJSON(json: any): Order {
  return OrderFromJSONTyped(json, false);
}

export function OrderFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): Order {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json['id'],
    createdAt: new Date(json['created_at']),
    total: json['total'],
    source: json['source'],
  };
}

export function OrderToJSON(value?: Order | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    created_at: value.createdAt.toISOString(),
    total: value.total,
    source: value.source,
  };
}
