/* tslint:disable */
/* eslint-disable */
/**
 * channels-com
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  HTTPValidationError,
  HTTPValidationErrorFromJSON,
  HTTPValidationErrorToJSON,
  ListMarketsResponse,
  ListMarketsResponseFromJSON,
  ListMarketsResponseToJSON,
} from '../models';

export interface ListMarketsMarketsGetRequest {
  acceptLanguage?: string;
}

/**
 *
 */
export class MarketsApi extends runtime.BaseAPI {
  /**
   * List Markets
   */
  async listMarketsMarketsGetRaw(
    requestParameters: ListMarketsMarketsGetRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<ListMarketsResponse>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      requestParameters.acceptLanguage !== undefined &&
      requestParameters.acceptLanguage !== null
    ) {
      headerParameters['accept-language'] = String(
        requestParameters.acceptLanguage
      );
    }

    const response = await this.request(
      {
        path: `/markets/`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ListMarketsResponseFromJSON(jsonValue)
    );
  }

  /**
   * List Markets
   */
  async listMarketsMarketsGet(
    requestParameters: ListMarketsMarketsGetRequest,
    initOverrides?: RequestInit
  ): Promise<ListMarketsResponse> {
    const response = await this.listMarketsMarketsGetRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }
}
