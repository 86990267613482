/* tslint:disable */
/* eslint-disable */
/**
 * channels-com
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import {
  HTTPValidationError,
  HTTPValidationErrorFromJSON,
  HTTPValidationErrorToJSON,
  ListOrdersResponse,
  ListOrdersResponseFromJSON,
  ListOrdersResponseToJSON,
  OrderReportResponse,
  OrderReportResponseFromJSON,
  OrderReportResponseToJSON,
  SingleOrderResponse,
  SingleOrderResponseFromJSON,
  SingleOrderResponseToJSON,
} from '../models';

export interface ExportOrderReportOrdersReportIdGetRequest {
  id: number;
  acceptLanguage?: string;
}

export interface GetSingleOrderOrdersIdGetRequest {
  id: number;
  acceptLanguage?: string;
}

export interface ListOrdersOrdersGetRequest {
  page?: number;
  perPage?: number;
  createdAt?: Date;
}

/**
 *
 */
export class OrdersApi extends runtime.BaseAPI {
  /**
   * This endpoint is used to export single order as an excel file for current Channel. as list of : The excel has the following columns: Date : Datetime as 2xxx-xx-xx GiftCardName: str GiftCardVariant: str Code: str Pin: str Price: float ExpiryDate : Datetime as 2xxx-xx-xx VAT : Int
   * Export Order Report
   */
  async exportOrderReportOrdersReportIdGetRaw(
    requestParameters: ExportOrderReportOrdersReportIdGetRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<OrderReportResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling exportOrderReportOrdersReportIdGet.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      requestParameters.acceptLanguage !== undefined &&
      requestParameters.acceptLanguage !== null
    ) {
      headerParameters['accept-language'] = String(
        requestParameters.acceptLanguage
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('HTTPBearer', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/orders/report/{id}`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      OrderReportResponseFromJSON(jsonValue)
    );
  }

  /**
   * This endpoint is used to export single order as an excel file for current Channel. as list of : The excel has the following columns: Date : Datetime as 2xxx-xx-xx GiftCardName: str GiftCardVariant: str Code: str Pin: str Price: float ExpiryDate : Datetime as 2xxx-xx-xx VAT : Int
   * Export Order Report
   */
  async exportOrderReportOrdersReportIdGet(
    requestParameters: ExportOrderReportOrdersReportIdGetRequest,
    initOverrides?: RequestInit
  ): Promise<OrderReportResponse> {
    const response = await this.exportOrderReportOrdersReportIdGetRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * This endpoint is used to show single order for the current Channel. as list of : order_id as id of type int order_data as created_at of type datetime order_total as total of type float order_items as items of type cartItem
   * Get Single Order
   */
  async getSingleOrderOrdersIdGetRaw(
    requestParameters: GetSingleOrderOrdersIdGetRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<SingleOrderResponse>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        'id',
        'Required parameter requestParameters.id was null or undefined when calling getSingleOrderOrdersIdGet.'
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      requestParameters.acceptLanguage !== undefined &&
      requestParameters.acceptLanguage !== null
    ) {
      headerParameters['accept-language'] = String(
        requestParameters.acceptLanguage
      );
    }

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('HTTPBearer', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/orders/{id}`.replace(
          `{${'id'}}`,
          encodeURIComponent(String(requestParameters.id))
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      SingleOrderResponseFromJSON(jsonValue)
    );
  }

  /**
   * This endpoint is used to show single order for the current Channel. as list of : order_id as id of type int order_data as created_at of type datetime order_total as total of type float order_items as items of type cartItem
   * Get Single Order
   */
  async getSingleOrderOrdersIdGet(
    requestParameters: GetSingleOrderOrdersIdGetRequest,
    initOverrides?: RequestInit
  ): Promise<SingleOrderResponse> {
    const response = await this.getSingleOrderOrdersIdGetRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }

  /**
   * This endpoint is used to list all orders of the current Channel. it does not take any parameter, it returns all orders of the current Channel. as list of : order_id as id of type uuid order_data as order_data of type datetime order_total as total of type float
   * List Orders
   */
  async listOrdersOrdersGetRaw(
    requestParameters: ListOrdersOrdersGetRequest,
    initOverrides?: RequestInit
  ): Promise<runtime.ApiResponse<ListOrdersResponse>> {
    const queryParameters: any = {};

    if (requestParameters.page !== undefined) {
      queryParameters['page'] = requestParameters.page;
    }

    if (requestParameters.perPage !== undefined) {
      queryParameters['per_page'] = requestParameters.perPage;
    }

    if (requestParameters.createdAt !== undefined) {
      queryParameters['created_at'] = (
        requestParameters.createdAt as any
      ).toISOString();
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('HTTPBearer', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/orders/`,
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides
    );

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      ListOrdersResponseFromJSON(jsonValue)
    );
  }

  /**
   * This endpoint is used to list all orders of the current Channel. it does not take any parameter, it returns all orders of the current Channel. as list of : order_id as id of type uuid order_data as order_data of type datetime order_total as total of type float
   * List Orders
   */
  async listOrdersOrdersGet(
    requestParameters: ListOrdersOrdersGetRequest,
    initOverrides?: RequestInit
  ): Promise<ListOrdersResponse> {
    const response = await this.listOrdersOrdersGetRaw(
      requestParameters,
      initOverrides
    );
    return await response.value();
  }
}
