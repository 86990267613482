/* tslint:disable */
/* eslint-disable */
/**
 * channels-com
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  ChannelDenomination,
  ChannelDenominationFromJSON,
  ChannelDenominationFromJSONTyped,
  ChannelDenominationToJSON,
} from './';

/**
 *
 * @export
 * @interface RedemptionCard
 */
export interface RedemptionCard {
  /**
   *
   * @type {number}
   * @memberof RedemptionCard
   */
  cartItemId: number;
  /**
   *
   * @type {ChannelDenomination}
   * @memberof RedemptionCard
   */
  channelDenomination: ChannelDenomination;
  /**
   *
   * @type {string}
   * @memberof RedemptionCard
   */
  usageAr?: string;
  /**
   *
   * @type {string}
   * @memberof RedemptionCard
   */
  usageEn?: string;
  /**
   *
   * @type {string}
   * @memberof RedemptionCard
   */
  titleAr?: string;
  /**
   *
   * @type {string}
   * @memberof RedemptionCard
   */
  titleEn?: string;
  /**
   *
   * @type {string}
   * @memberof RedemptionId
   */
  redemptionId: string;
  /**
   *
   * @type {string}
   * @memberof RedemptionCard
   */
  redemptionCode: string;
  /**
   *
   * @type {string}
   * @memberof RedemptionCard
   */
  redemptionPin?: string;
  /**
   *
   * @type {string}
   * @memberof RedemptionCard
   */
  serial?: string;
  /**
   *
   * @type {Date}
   * @memberof RedemptionCard
   */
  validFrom: Date;
  /**
   *
   * @type {Date}
   * @memberof RedemptionCard
   */
  validTo?: Date;
}

export function RedemptionCardFromJSON(json: any): RedemptionCard {
  return RedemptionCardFromJSONTyped(json, false);
}

export function RedemptionCardFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): RedemptionCard {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    cartItemId: json['cart_item_id'],
    channelDenomination: ChannelDenominationFromJSON(
      json['channel_denomination']
    ),
    usageAr: !exists(json, 'usage_ar') ? undefined : json['usage_ar'],
    usageEn: !exists(json, 'usage_en') ? undefined : json['usage_en'],
    titleAr: !exists(json, 'title_ar') ? undefined : json['title_ar'],
    titleEn: !exists(json, 'title_en') ? undefined : json['title_en'],
    redemptionId: json['redemption_id'],
    redemptionCode: json['redemption_code'],
    redemptionPin: !exists(json, 'redemption_pin')
      ? undefined
      : json['redemption_pin'],
    serial: !exists(json, 'serial') ? undefined : json['serial'],
    validFrom: new Date(json['valid_from']),
    validTo: !exists(json, 'valid_to') ? undefined : new Date(json['valid_to']),
  };
}

export function RedemptionCardToJSON(value?: RedemptionCard | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    cart_item_id: value.cartItemId,
    channel_denomination: ChannelDenominationToJSON(value.channelDenomination),
    usage_ar: value.usageAr,
    usage_en: value.usageEn,
    title_ar: value.titleAr,
    title_en: value.titleEn,
    redemption_id: value.redemptionId,
    redemption_code: value.redemptionCode,
    redemption_pin: value.redemptionPin,
    serial: value.serial,
    valid_from: value.validFrom.toISOString(),
    valid_to:
      value.validTo === undefined ? undefined : value.validTo.toISOString(),
  };
}
