/* tslint:disable */
/* eslint-disable */
/**
 * channels-com
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
  ChannelCard,
  ChannelCardFromJSON,
  ChannelCardFromJSONTyped,
  ChannelCardToJSON,
  Meta,
  MetaFromJSON,
  MetaFromJSONTyped,
  MetaToJSON,
} from './';

/**
 *
 * @export
 * @interface ChannelCardsPaginatedResponse
 */
export interface ChannelCardsPaginatedResponse {
  /**
   *
   * @type {Array<ChannelCard>}
   * @memberof ChannelCardsPaginatedResponse
   */
  data: Array<ChannelCard>;
  /**
   *
   * @type {Meta}
   * @memberof ChannelCardsPaginatedResponse
   */
  meta: Meta;
}

export function ChannelCardsPaginatedResponseFromJSON(
  json: any
): ChannelCardsPaginatedResponse {
  return ChannelCardsPaginatedResponseFromJSONTyped(json, false);
}

export function ChannelCardsPaginatedResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): ChannelCardsPaginatedResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: (json['data'] as Array<any>).map(ChannelCardFromJSON),
    meta: MetaFromJSON(json['meta']),
  };
}

export function ChannelCardsPaginatedResponseToJSON(
  value?: ChannelCardsPaginatedResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: (value.data as Array<any>).map(ChannelCardToJSON),
    meta: MetaToJSON(value.meta),
  };
}
