import { Transition } from '@headlessui/react';
import React, { SetStateAction, useRef, useState } from 'react';
import { useClickAway, useDebounce } from 'react-use';

interface SortControl {
  id: string;
  message: string;
}

export interface ListControlsProps {
  sortTitle: string;
  searchTitle: string;
  onSearchChange: (phrase: string) => void;
  onSortChange: (id: SetStateAction<string>) => void;

  defaultSearchValue?: string;
  sortItems: SortControl[];
}

export function ListControls({
  sortTitle,
  searchTitle,
  onSearchChange,
  onSortChange,
  defaultSearchValue = '',
  sortItems,
}: ListControlsProps) {
  const [mode, setMode] = useState<'hidden' | 'search' | 'sort'>('hidden');
  const [term, setTerm] = useState(defaultSearchValue);
  const ref = useRef<HTMLDivElement | null>(null);
  useClickAway(ref, (evt) => {
    if (
      mode === 'hidden' ||
      (evt.target instanceof Element &&
        (evt.target.id === 'search-options-menu' ||
          evt.target.id === 'sort-options-menu'))
    ) {
      return;
    }
    setMode('hidden');
  });
  useDebounce(
    () => {
      onSearchChange(term);
    },
    500,
    [term]
  );
  return (
    <div className="relative inline-block text-left">
      <div className="flex h-full">
        <button
          type="button"
          className="inline-flex justify-center px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-identity-primary rounded-md"
          id="search-options-menu"
          aria-expanded="true"
          aria-haspopup="true"
          onClick={() => {
            setMode((mode) => (mode !== 'search' ? 'search' : 'hidden'));
          }}
        >
          <span className="sr-only pointer-events-none">Search</span>
          <svg
            className="h-5 w-5 pointer-events-none"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            aria-hidden="true"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            />
          </svg>
        </button>
        <button
          type="button"
          className="inline-flex justify-center px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-identity-primary mis-2 rounded-md"
          id="sort-options-menu"
          aria-expanded={mode !== 'hidden'}
          aria-haspopup="true"
          onClick={() => {
            setMode((mode) => (mode !== 'sort' ? 'sort' : 'hidden'));
          }}
        >
          <span className="sr-only pointer-events-none">Sort</span>
          <svg
            className="h-5 w-5 pointer-events-none"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            aria-hidden="true"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
            />
          </svg>
          <span className="w-11 pointer-events-none">{sortTitle}</span>
        </button>
      </div>
      <div ref={ref}>
        <Transition
          show={mode !== 'hidden'}
          className="origin-top-right z-10 absolute inline-end-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-label="card search and sort menu"
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <div className="py-1" role="none">
            {mode === 'search' ? (
              <div className="p-4">
                <input
                  type="search"
                  className="p-1 px-2 text-rgray rounded w-full border-none bg-gray-100 focus:ring-identity-primary"
                  placeholder={searchTitle}
                  value={term}
                  autoFocus
                  onChange={(evt) => {
                    setTerm(evt.target.value);
                  }}
                />
              </div>
            ) : mode === 'sort' ? (
              sortItems.map((item, idx, { length }) => (
                <div
                  className={`p-4 ${idx < length - 1 ? 'pb-0' : ''}`}
                  key={item.id}
                >
                  <button
                    className="p-1 px-2 text-rgray rounded w-full border-none bg-gray-100 focus:ring-identity-primary"
                    onClick={() => {
                      onSortChange(item.id);
                      setMode('hidden');
                    }}
                  >
                    {item.message}
                  </button>
                </div>
              ))
            ) : null}
          </div>
        </Transition>
      </div>
    </div>
  );
}

export default ListControls;
