/* tslint:disable */
/* eslint-disable */
/**
 * channels-com
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface UserUpdatePassword
 */
export interface UserUpdatePassword {
  /**
   *
   * @type {string}
   * @memberof UserUpdatePassword
   */
  newPassword: string;
  /**
   *
   * @type {string}
   * @memberof UserUpdatePassword
   */
  oldPassword: string;
}

export function UserUpdatePasswordFromJSON(json: any): UserUpdatePassword {
  return UserUpdatePasswordFromJSONTyped(json, false);
}

export function UserUpdatePasswordFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): UserUpdatePassword {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    newPassword: json['new_password'],
    oldPassword: json['old_password'],
  };
}

export function UserUpdatePasswordToJSON(
  value?: UserUpdatePassword | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    new_password: value.newPassword,
    old_password: value.oldPassword,
  };
}
