/* tslint:disable */
/* eslint-disable */
/**
 * channels-com
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface OrderReportResponse
 */
export interface OrderReportResponse {
  /**
   *
   * @type {string}
   * @memberof OrderReportResponse
   */
  reportLink: string;
}

export function OrderReportResponseFromJSON(json: any): OrderReportResponse {
  return OrderReportResponseFromJSONTyped(json, false);
}

export function OrderReportResponseFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): OrderReportResponse {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    reportLink: json['report_link'],
  };
}

export function OrderReportResponseToJSON(
  value?: OrderReportResponse | null
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    report_link: value.reportLink,
  };
}
